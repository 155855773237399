<template>
    <div>
        <div ref="parentElement" id="outside" @resize="handleResize">
            <div id="inside" :style="`width: calc(${this.setWidth}${'px'});`">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            outsideWidth:0,
            setWidth:0
        }
    },
    watch:{
        outsideWidth:function(newVal,val){
            this.calOutsideWidth();
        },
    },
    props: ['percentage'],
    mounted() {
        this.getParentWidth();
        window.addEventListener('resize',this.getParentWidth)
    },
    methods:{
        calOutsideWidth:function(){
        this.setWidth=this.percentage * this.outsideWidth>this.outsideWidth?this.outsideWidth:this.percentage * this.outsideWidth;
        },
        getParentWidth(){
            const parentElement=this.$refs.parentElement;
            if(parentElement){
                this.outsideWidth=parentElement.clientWidth;
            }
        },
        handleResize(){
            this.getParentWidth();
        }
    },
    destroyed(){
        window.removeEventListener('resize',this.getParentWidth);
    }
};
</script>

<style>
#outside {
    background: #EDEEEF;
    height: 5px;
    border-radius: 10px;
}

#inside {
    height: 5px;
    background: #e7aa0f;
    border-radius: 10px;
}
</style>