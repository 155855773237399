<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('bank_account.title') }}</h1>
                </div>
            </div>
            <div class="card">

                <!-- loading indicator -->
                <div v-if="loading" class="card-body">
                    <loading-indicator />
                </div>
                <div v-else class="card-body">
                    <!-- loading error -->
                    <div v-if="error" class="alert alert-danger">{{ $t('bank_account.load_error') }}</div>

                    <!-- loaded -->
                    <form v-else method="post" action="javascript:void(0)" v-on:submit="toggleConfirm">
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_account_name_my' : 'bank_account.label_account_name') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input name="bank_card_holder" v-model="bank_card_holder" class="form-control" data-val="true" data-val-required="*" />
                                </div>
                            </div>
                        </div>
                        <!-- Branch isn't required by the HK region. -->
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{ $t('bank_account.label_branch') }}
                                        <span v-if="sysconfig.is_hk">({{ $t('general.optional') }})</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input v-if="sysconfig.is_hk" type="text" name="branch" v-model="branch" class="form-control" :placeholder="$t('general.optional')" />
                                    <input v-else type="text" name="branch" v-model="branch" class="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label class="form-label">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_card_no_my' : 'bank_account.label_card_no') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="alert alert-warning shadow-sm text-lg" v-if="formatted_card_no">
                                        <b>{{ formatted_card_no }}</b>
                                    </div>
                                    <input type="text" name="card_no" v-model="card_no" maxlength="128" pattern="\d*" class="form-control" data-val="true" data-val-required="*" data-val-regex="*" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-6">
                                    <button class="btn btn-primary" type="submit">{{ $t('general.submit') }}</button>
                                    <a v-if="id" href="javascript:;" @click="toggleDelete" class="btn btn-secondary">{{ $t('bank_account.label_delete') }}</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal" id="bank-modal" ref="confirmModal">
            <form method="post" action="javascript:void(0)" @submit="updateFunc">
                <input type="hidden" name="Uid" v-model="id" />
                <input type="hidden" name="IDNumber" v-model="id_number" />
                <input type="hidden" name="BankCardHolder" v-model="bank_card_holder" />
                <input type="hidden" name="Bank" v-model="bank" />
                <input type="hidden" name="Branch" v-model="branch" />
                <input type="hidden" name="BankCardNo" v-model="card_no" />

                <div class="modal-dialog" role="document" id="cardModel">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('bank_account.label_confirm') }}</h4>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-warning">{{ $t('bank_account.label_confirm_desp') }}</div>
                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_account_name_my' : 'bank_account.label_account_name') }}</div>
                                <div class="col-8">{{ bank_card_holder }}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_bank_my' : 'bank_account.label_bank') }}</div>
                                <div class="col-8">{{ countryItem }} {{ bank }} {{ branch }}</div>
                            </div>
                            <div class="form-group row">
                                <div class="col-4 text-right text-muted">{{ $t(sysconfig.is_hkmy ? 'bank_account.label_card_no_my' : 'bank_account.label_card_no') }}</div>
                                <div class="col-8 text-warning">
                                    <b>{{ formatted_card_no }}</b>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default cancel-button" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                            <button v-if="updating" type="submit" class="btn btn-primary btn-loading" disabled>{{ $t('general.confirm') }}</button>
                            <button v-else type="submit" class="btn btn-primary">{{ $t('general.confirm') }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal" ref="deleteModal">
            <form method="post" action="javascript:void(0)" @submit="deleteFunc">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('bank_account.label_delete_title') }}</h4>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-warning">{{ $t('bank_account.label_delete_desp') }}</div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default cancel-button" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                            <button type="submit" class="btn btn-primary" :class="{ 'btn-loading': updating }" :disabled="updating">{{ $t('general.confirm') }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>
<script>
// import { getConfigAsync } from 'utilities/helper';
import SmsVerificationComponent from '../Components/SmsVerification.vue';
import { Modal } from 'bootstrap';

export default {
    props: ['id'],
    components: { SmsVerificationComponent },

    data() {
        return {
            // page state
            loading: true,
            error: false,

            // bank account information
            id_number: null,
            bank_card_holder: null,
            bank: null,
            branch: null,
            card_no: null,
            vcode: null,
            country:'',
            countryItem: '',

            updating: false,
            requires_sms_verification: false,

            // The popup confirmation modal.
            confirm_modal: null,
            delete_modal: null,

        };
    },
    
    watch: {
        '$i18n.locale': function (to_val, from_val) {
            this.initAsync();
        }
    },

    created() {
        // Read current bank account
        this.initAsync();
    },

    computed: {
        // returns the formatted card no.
        formatted_card_no: function () {
            let t = $.trim(this.card_no);
            if (t && t.length) {
                t = t.replace(/\s+/, '');
                const arr = [];
                for (let i = 0; i < t.length; i += 4) {
                    if (i + 4 > t.length) {
                        arr.push(t.substr(i));
                    } else {
                        arr.push(t.substr(i, 4));
                    }
                }
                return arr.join(' ');
            }
            return '';
        }
    },

    mounted() {
        this.confirm_modal = new Modal(this.$refs.confirmModal);
        this.delete_modal = new Modal(this.$refs.deleteModal);
    },

    beforeDestroy() {
        let m = this.confirm_modal;
        if (m) {
            m.dispose();
        }
        m = this.delete_modal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;
            const uid = this.id * 1;
            if (!isNaN(uid) && uid > 0) {
                try {
                    // get current bank account
                    const json = await $.callGetApi(this, `/api/v1/bankaccount/query?id=${uid}`);
                    if (json && json.errcode === 0) {
                        const data = json.data;
                        let bankData = data.bank.split('/')
                        self.bank = bankData[1];
                        self.country = bankData[0];
                        self.branch = data.branch;
                        self.bank_card_holder = data.bankCardHolder;
                        self.card_no = data.bankCardNo;
                        self.id_number = data.idNumber;

                        // read system config
                        // const config = await getConfigAsync();

                        // TODO:
                        // Do not support sms verification for now.
                        // self.requires_sms_verification = !!data.bankCardNo && config.bankAccountVCodeRequired;
                        self.requires_sms_verification = false;

                        self.loading = false;

                        Vue.nextTick(() => {
                            $.resetValidators();
                        });
                    }
                } catch (err) {
                    console.error(`ERROR: ${err}`);
                    self.error = true;
                }
            } else {
                self.loading = false;

                Vue.nextTick(() => {
                    $.resetValidators();
                });
            }
        },

        selectCountry(country){
            this.country = country.name;
            this.showCountry = false;
            this.noCountry = false;
        },

        toggleConfirm: function (e) {
            const frm = $(e.target);
            if (frm.valid()) {
                // display a confirmation dialog in 250ms
                this.updating = false;

                if (this.requires_sms_verification) {
                    this.vcode = this.$refs.smsComp.getSmsCode();
                }
                // $('#bank-modal').modal({ dismissible: false }).modal('show');
                this.confirm_modal.show();
            }
        },

        countryChange(){
          let data = this.countryList.find(item=>item.key === this.country);
          this.countryName = data.name;
        },

        updateFunc: function (e) {
            const frm = $(e.target);

            if (frm.valid()) {
                const data = frm.serializeAsJson();
                this.callBankAccountApi(g_server_root + '/api/v1/bankaccount', data);
            }
        },

        toggleDelete: function () {
            this.delete_modal.show();
        },

        deleteFunc: function (e) {
            const uid = this.id * 1;
            if (!isNaN(uid) && uid > 0) {
                this.callBankAccountApi(g_server_root + '/api/v1/bankaccount/delete?id=' + uid, null);
            }
        },

        callBankAccountApi: function (url, frmData) {
            const self = this;
            if(frmData){
                // frmData.Bank = this.country +'/'+ frmData.Bank
                frmData.Bank = frmData.Branch
            }

            self.updating = true;
            this.$http
                .post(url, frmData)
                .then((resp) => {
                    const json = resp.data;

                    if (json.errcode === 0) {
                        let callback = function () {
                            self.updating = false;
                            // $('#bank-modal').modal('hide');
                            self.confirm_modal.hide();
                            self.delete_modal.hide();

                            $.top_alert(self.$t('general.operation_succeeded'));
                            self.$router.push('/user/bankaccounts');
                        };
                        setTimeout(callback, 1000);
                    } else {
                        self.updating = false;
                        $.top_error(json.errmsg || self.$t('general.operation_error'));
                    }
                })
                .catch((err) => {
                    console.error('ERROR: ' + err);
                    $.top_error(self.$t('general.operation_error'));
                    self.updating = false;
                });
        }
    }
};
</script>