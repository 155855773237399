<template>
    <div class="row">
        <loading-indicator v-if="!balance_list" />

        <div v-else class="col balance-list">
            <dl class="title-row">
                <dt>
                    <div class="hidden-sm">{{ $t('assets.account_type') }}</div>
                    <div>{{ $t('assets.balance') }}</div>
                    <div>{{ $t('assets.available_balance') }}</div>
                    <div>{{ $t('assets.frozen') }}</div>

                    <!-- <div class="hidden-sm">deposit/withdraw actions</div> -->
                    <!-- add operation title -->
                    <div class="hidden-sm-right text-center">{{ $t('assets.frozen') }}</div>
                </dt>
            </dl>
            <!-- balance_list_new -->
            <dl v-for="balance in balance_list" class="currency-row" :key="balance.currency">
                <dd>
                    <!-- <div class="pe-2"><token-icon :symbol="symbol" /></div> -->
                    <div>{{ getTokenName(balance) }}</div>
                    <div class="mobile-balance1">{{ $t('assets.balance') }}</div>
                    <div class="mobile-balance2">{{ $t('assets.available_balance') }}{{ ':' }}</div>
                    <div class="mobile-balance3">{{ $t('assets.frozen') }}{{ ':' }}</div>
                    <div>{{ balance.balance }}</div>
                    <div class="num-style" v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div class="num-style" v-else>{{ balance.available }}</div>
                    <div class="num-style">{{ balance.frozen }}</div>
                    <div class="operation-img">
                        <template v-if="balance.currency !== 'FTUSDT'">
                            <svg viewBox="0 0 256 256" class="svg-seeMore" @click="showActionsModel(balance)">
                                <use xlink:href="/dist/svg/icons.svg#seeMore" />
                            </svg>
                        </template>
                        <template v-else-if="balance.currency === 'FTUSDT'">
                            <svg viewBox="0 0 256 256" class="svg-seeMore see-none">
                                <use xlink:href="/dist/svg/icons.svg#seeMore" />
                            </svg>
                        </template>
                    </div>
                    <div class="actions">
                        <template v-if="isDepositSupported(balance.currency)">
                            <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                {{ $t('general.deposit') }}
                            </a>
                            <router-link class="deposite"
                                :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{
                                    $t('general.withdraw') }}</router-link>
                        </template>
                        <template v-else-if="balance.currency !== 'FTUSDT'">
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                        </template>
                    </div>
                </dd>
                <!-- display deposit address -->
                <div v-if="isDepositSupported(balance.currency) && deposit_currency === balance.currency" class="deposit-address-block">
                    <deposit-form :bcConfig="bcConfig" :coinConfig="getCoinConfig(balance.currency)" />
                </div>
            </dl>
        </div>
        <div v-if="isShow">
            <global-mask v-if="showGlobalMask" />
            <currency-actions-model class="top-float" ref="actionsModel" :balance_list="balance_list" :Bname="Bname"
                :isShow="isShow" :bcConfig="bcConfig" :futures_assets="futures_assets"
                @hiddenMask="hideMask"></currency-actions-model>
        </div>
    </div>
</template>
<style scoped>
.top-float {
    position: fixed;
    bottom: 4.8rem;
    width: 100%;
    left: 0;
    margin: auto;
}

.svg-seeMore {
    height: 1rem;
}

.svg-seeMore.see-none {
    display: none;
}
</style>
<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';
import CurrencyActionsModel from '../Components/CurrencyActionsModel.vue';
import GlobalMask from '../Components/GlobalMask.vue';
import '@/assets/svg/ko-v4.2/seeMore.svg';
// import {filteredSymbols} from '../Components/SymbolList.vue';

export default {
    components: { DepositForm, CurrencyActionsModel, GlobalMask },
    props: ['balance_list', 'futures_assets', 'bcConfig',],
    data() {
        return {
            coinMap: {},
            isShow: false,
            // The active currency for deposits
            deposit_currency: '',
            //Limit the displayed currency names
            Bname: '',
            showGlobalMask: false,
        }
    },

    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);
    },
    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];
        },
        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        },
        showActionsModel: function (name) {
            this.Bname = this.getTokenName(name);
            const Cname = this.Bname;
            this.isShow = true;
            this.showMask();
        },
        showMask() {
            this.showGlobalMask = true;
        },
        hideMask() {
            this.showGlobalMask = false;
            this.isShow = false
        }
    }
}
</script>