<template>
  <form
    class="login-form"
    method="post"
    action="javascript:void(0)"
    v-on:submit="do_login"
  >
    <div>
      <svg viewBox="-94 0 256 256" class="login-icon">
        <use xlink:href="/dist/svg/icons.svg#ko-v4.2-login" />
      </svg>
    </div>
    <input
      type="hidden"
      name="__RequestVerificationToken"
      v-bind:value="csrfToken"
    />
    <div class="row">
      <div class="col">
        <h1>{{ $t("login.title") }}</h1>
      </div>
    </div>
    <!--
            Does both phone number and email are supported?
        -->
    <div
      class="page-top-nav mb-3"
      v-if="sysconfig.phoneSupported && sysconfig.emailSupported"
    >
      <li :class="{ active: emailVCode }">
        <a @click="emailVCode = true" href="javascript:;">{{
          $t("login.label_email")
        }}</a>
      </li>
      <li :class="{ active: !emailVCode }">
        <a @click="emailVCode = false" href="javascript:;">{{
          $t("login.account")
        }}</a>
      </li>
    </div>

    <div class="form-group">
      <!-- phone--select--area -->
      <label
        v-if="!(sysconfig.phoneSupported && sysconfig.emailSupported)"
        class="form-label"
        for="UserName"
        >{{ $t("login.account") }}</label
      >
      <template v-if="emailVCode">
        <!-- Email account -->
        <!-- Required by the interface -->
        <input type="hidden" name="RegionCode" value="+1" />
        <input
          name="UserName"
          type="text"
          autocomplete="off"
          data-val="true"
          data-val-required="*"
          data-val-regex="*"
          data-val-regex-pattern="^[^@]+@[^@]{3,}$"
          class="form-control"
          :placeholder="$t('login.label_email')"
        />
      </template>
      <div v-else class="d-flex">
        <region-selector v-bind:regions="regions" />
        <div class="flex-fill">
          <input
            name="UserName"
            data-val="true"
            data-val-required="*"
            pattern="\d*"
            type="text"
            value=""
            class="form-control round-right phone"
            :placeholder="$t('login.account')"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <!-- <label for="Password">{{ $t('login.password') }}</label> -->
      <div class="pwd-box">
        <input
          name="Password"
          autocomplete="off"
          data-val="true"
          data-val-required="*"
          class="form-control"
          :placeholder="$t('login.password')"
          :type="showPassword ? 'text' : 'password'"
          v-model="pwd"
        />
        <svg
          viewBox="0 0 256 256"
          class="svg-icon showPwd-icon"
          @click="showPassword = !showPassword"
          :class="showPassword ? 'active' : ''"
        >
          <use xlink:href="/dist/svg/icons.svg#v4.2-displayPassword" />
        </svg>
      </div>
    </div>
    <!-- Google Authenticator Code -->
    <!-- <div class="form-group">
            <label class="form-label" for="GAPin">{{ $t('login.ga_pin') }}</label>
            <input name="GAPin" type="text" autocomplete="off" maxlength="6" pattern="\d*" data-val-regex="*" data-val-regex-pattern="^\d{6}$" class="form-control" :placeholder="$t('login.ga_pin')" />
            <div class="help-block">{{ $t('login.ga_pin_desp') }}</div>
        </div> -->
    <div class="form-group">
      <submit-button ref="submitButton" :text="$t('login.login_button')" />
    </div>
    <div class="form-group">
      <div class="row">
        <!-- Do we need to display the registration link? If could be configured on the server. -->
        <div class="col text-left">
          <router-link to="/user/create" class="linkColor">{{
            $t("login.free_register")
          }}</router-link>
        </div>
        <div class="col text-right">
          <router-link to="/user/reset" class="linkColor">{{
            $t("login.forgot_password")
          }}</router-link>
        </div>
      </div>
    </div>
  </form>
</template>
<style scoped>
.pwd-box {
  position: relative;
}
.pwd-box .showPwd-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
}
.showPwd-icon.active {
  fill: var(--primary-base);
  stroke: var(--primary-base);
}

.login-icon {
  width: 702px;
  height: 454px;
  position: absolute;
  left: -50rem;
}

@media (max-width: 1024px) {
  .login-container .login-icon {
    display: none;
  }
}
</style>
<script>
import RegionSelector from "../../Components/RegionSelectorV2.vue";
import { setProfile } from "utilities/helper";
import "@/assets/svg/v4.2/v4.2-displayPassword.svg";
import "@/assets/svg/ko-v4.2/ko-v4.2-login.svg";
export default {
  components: { RegionSelector },

  props: ["regions", "csrfToken"],

  created() {
    const regions = this.regions;
    if (!regions || !Array.isArray(regions))
      throw new Error("An array of supported regions is required.");

    const token = this.csrfToken;
    if (!token || typeof token !== "string")
      throw new Error("A valid CSRF token is required.");

    if (this.supportMultipleRegions) {
      this.regionCode = regions[0].areaCode;
    }
  },

  data() {
    return {
      regionCode: null,
      // which types of account is in use?
      emailVCode: false,
      showPassword: false,
      pwd: ""
    };
  },

  watch: {
    emailVCode: function () {
      Vue.nextTick(() => {
        $.resetValidators();
      });
    }
  },
  computed: {
    supportMultipleRegions: function () {
      const regions = this.regions;
      return regions != null && regions.length > 0;
    }
  },

  mounted() {
    $.resetValidators();
  },

  methods: {
    do_login: function (e) {
      const self = this;
      const frm = $(e.target);
      if (frm.valid()) {
        self.$refs.submitButton.submit();

        const _on_submit_error = function (err) {
          const _callback = function () {
            $.top_error(err || self.$t("login.login_failed"));
            self.$refs.submitButton.reset();
          };
          setTimeout(_callback, 5000);
        };

        self.$http
          .post("/api/v1/account/signin", frm.serialize(), {
            headers: { "X-XSRF-TOKEN": self.csrfToken }
          })
          .then((resp) => {
            const json = resp.data;

            if (json && json.errcode == 0) {
              // Save the user profile.
              setProfile(json.data);

              self.$refs.submitButton.complete();
              setTimeout(function () {
                // Redirect to user home if not specified.
                const from = $.trim(self.$route.query.from);
                if (from.length && from.startsWith("/")) {
                  self.$router.push(from);
                } else {
                  self.$router.push("/user");
                }
              }, 200);
            } else {
              _on_submit_error(json.errmsg);
            }
          })
          .catch((err) => {
            console.log("Login failed" + JSON.stringify(err));
            _on_submit_error();
          });
      }
    }
  }
};
</script>