<template>
    <div>
        <div class="settings-card">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="settings-item">
                            <router-link to="/user/balance">
                                {{ $t('home.footer_assets') }}
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <router-link to="/exchange/orders">
                            {{ $t('home.header_exchange_orders') }}
                            <svg viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                            </svg>
                        </router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <router-link to="/futures/openorders">
                            {{ $t('home.header_futures_orders') }}
                            <svg viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                            </svg>
                        </router-link>
                    </div>
                    <div v-if="sysconfig.bOptionEnabled" class="col-12 col-md-6">
                        <router-link to="/boption/orders">{{ $t('home.header_boption_orders') }}
                            <svg viewBox="0 0 256 256">
                                <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class=" settings-card">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/verifyid" class="d-flex">
                                        <div class="flex-fill">{{ $t('user.label_id_verification') }}</div>
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>

                        </div>
                        <div class="user-info" v-if="sysconfig.phoneSupported">
                            <router-link to="/user/phone" class="d-flex">
                                <div class="flex-fill">{{ $t('user.label_account') }}</div>
                                <div class="fw-normal me-2">{{ profile.phone }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div v-if="sysconfig.emailSupported">
                            <router-link to="/user/email" class="d-flex">
                                <div class="flex-fill">{{ $t('login.label_email') }}</div>
                                <div class="fw-normal me-2">{{ profile.email }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/signout">
                                        {{ $t('user.label_signout') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/notices">{{ $t('notices.notice_title') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/reset">
                                        {{ $t('user.label_password') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="sysconfig.requiresWithdrawPassword === true">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/changewithdrawpwd">
                                        {{ $t('user.label_financial_password') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link :to="jumpRoute">
                                        {{ $t('user.label_bankaccount') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default { props: ['profile','jumpRoute']};
</script>