<template>
    <section class="page startup-page">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">
            <ul class="page-top-nav mb-3">
                <!-- <li>
                    <router-link to="/startup?type=forecast">{{ $t('startup.title_forecast') }}</router-link>
                </li> -->
                <li>
                    <router-link to="/startup?type=ongoing">{{ $t('startup.title_ongoing') }}</router-link>
                </li>
                <li class="active">
                    <a href="javascript:;">{{ $t('startup.title_orders') }}</a>
                </li>
            </ul>
            <div class="table-control-boundary">
                <div v-if="orders && orders.length > 0" class="history-mobile">
                    <div v-for="order in orders" v-bind:key="order.uid" class="history-item">
                        <div class="history-card-header">
                            <div class="currency">
                                <div class="currency">
                                    <span>{{ $t('startup.orders_token') }}</span>
                                </div>
                                <div>{{ order.token }}</div>
                            </div>
                            <div class="time">
                                <div>
                                    {{ $t('startup.orders_time') }}
                                </div>
                                <div>
                                    <span class="d-none d-md-block">{{ new Date(order.timeCreated).formatDateTime()
                                        }}</span>
                                    <span class="d-block d-md-none">{{ new Date(order.timeCreated).formatDate()
                                        }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="card-item mid">
                            <div class="item-mid">
                                <div class="title">
                                    <span>{{ $t('startup.label_offer_price') }}</span>
                                </div>
                                <div>
                                    <span class="color-up">
                                        <span>{{ order.offerPrice }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="item-mid">
                                <div class="title">
                                <span>{{ $t('startup.orders_num_subscribed') }}</span>
                            </div>
                            <div>
                                {{ order.numSubscribed }}
                                <span v-if="order.status === 1" class="badge bg-primary">
                                    {{ $t('startup.status_subscribed') }}
                                </span>
                                <span v-else-if="order.status === 2" class="badge bg-primary">
                                    {{ $t('startup.status_processing') }}
                                </span>
                                <span v-else-if="order.status === 3" class="text-muted small">
                                    {{ $t('startup.status_granted') }}
                                </span>
                                <span v-else class="text-muted small">
                                    {{ $t('startup.status_other') }}
                                </span>
                            </div>
                            </div>
                            
                            <div class="item-mid">
                                <div class="title">
                                    <span>{{ $t('startup.orders_num_granted') }}</span>
                                </div>
                                <div>
                                    {{ order.status === 3 ? order.numGranted : '--' }}
                                </div>
                            </div>
                        </div>
                        <div class="card-item description">
                            <div class="time">
                                <div>
                                    <span>{{ $t('startup.orders_start_time') }}</span>
                                </div>
                                <div>
                                    {{ new Date(order.startTime).formatDateTime() }}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span>{{ $t('startup.orders_end_time') }}</span>
                                </div>
                                {{ new Date(order.endTime).formatDateTime() }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loading-indicator v-if="!orders" />
            <div v-else-if="orders.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </section>
    </section>
</template>
<style scoped>
.table>:not(caption)>*>* {
    background-color: #fff;
    color: #15181c;
}

.table.compact {
    font-size: 12px;
}

.table thead tr th {
    font-weight: normal;
    text-align: center;
}

.table tbody {
    margin-top: 1rem;
}

.table tbody tr td {
    text-align: center;
}

.table-control-boundary {
    width: 100%;
}


.history-mobile {
    background-color: #fff;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: block;
    margin-bottom: -8px;
    padding: 1rem;
    color: #969699;
}

.history-mobile .history-item {
    border: 1px solid #EDEEEF;
    margin: 1rem 0 1.3rem;
    padding: 1rem;
}

.history-card-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: .3rem;
}

.card-item {
    line-height: 1.5;
    display: flex;
    padding: .3rem;
    font-size: 10px;
}
.card-item.mid{
    line-height: 1.5;
    display: flex;
    padding: .3rem;
    justify-content: space-between;
}
.item-mid{
    display: flex;
    margin-right: 29px;
}
.card-item .title {
    margin-right: .5rem;
}

.time {
    display: flex;
    flex-direction: column;
}

.description {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.history-card-header .currency {
    font-size: 19px;
    font-weight: 700;
    display: flex;
    margin-right: 1rem;
    color: #15181c;
    margin-bottom: 0.2rem;
}
</style>
<script>
import StartupIcon from '../Components/_StartupIcon.vue';

export default {
    components: { StartupIcon },
    data() {
        return { orders: null };
    },

    created() {
        this.getOrdersAsync();
    },

    methods: {
        getOrdersAsync: async function () {
            const json = await $.callGetApi(this, '/api/v1/startuporder');
            if (json && json.errcode === 0) {
                this.orders = json.data;
            }
        }
    }
};
</script>