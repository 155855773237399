<template>
  <nav class="navbar d-md-none">
    <div
      ref="sideNav"
      class="offcanvas"
      :class="offcanvas_pos"
      tabindex="-1"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div class="offcanvas-header">
        <!-- signup or signout-->
        <template v-if="context.profile">
          <h5 class="offcanvas-title flex-fill" id="offcanvasNavbarLabel">
            {{ context.profile.name }}
            <div class="text-muted small">{{ context.profile.realName }}</div>
          </h5>
          <router-link class="btn btn-primary" to="/user/signout">{{
            $t("signout.title")
          }}</router-link>
        </template>
        <div v-else>
          <router-link class="btn btn-primary" to="/user/create">{{
            $t("general.register")
          }}</router-link>
          <router-link to="/user/login" class="btn btn-secondary">{{
            $t("general.login")
          }}</router-link>
        </div>

        <!-- close -->
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/"
                  :class="{ active: active_tab === '' }"
                  >{{ $t("home.title") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="list-group-item">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/user"
                  :class="{ active: active_tab === 'user' }"
                  >{{ $t("home.header_account_security") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/user/balance"
                  :class="{ active: active_tab === 'user/balance' }"
                >
                  {{ $t("home.footer_assets") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/notification"
                  :class="{ active: active_tab === 'notification' }"
                >
                  {{ $t("notification.notification") }}
                  <span :class="{ readFlag: readFlag === true }"></span>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="list-group-item">
            <!-- {{ $t('general.trade') }} -->
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/futures"
                  :class="{ active: active_tab === 'futures' }"
                >
                  {{ $t("general.futures") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/exchange"
                  :class="{ active: active_tab === 'exchange' }"
                >
                  {{ $t("general.exchange") }}
                </router-link>
              </li>
              <li v-if="sysconfig.bOptionEnabled" class="nav-item">
                <router-link
                  class="nav-link"
                  to="/boption"
                  :class="{ active: active_tab === 'boption' }"
                >
                  {{ $t("general.boption") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/startup"
                  :class="{ active: active_tab === 'startup' }"
                >
                  {{
                    $t(
                      sysconfig.is_hk ? "general.startup_hk" : "general.startup"
                    )
                  }}
                </router-link>
              </li>
              <li v-if="sysconfig.savingsEnabled" class="nav-item">
                <router-link
                  class="nav-link"
                  to="/saving"
                  :class="{ active: active_tab === 'saving' }"
                >
                  {{ $t("home.header_saving") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/college"
                  :class="{ active: active_tab === 'college' }"
                >
                  {{ $t("home.header_college") }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="list-group-item">
            <!-- {{ $t('home.header_orders') }} -->
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  to="/futures/openorders"
                  class="nav-link"
                  :class="{ active: active_tab === 'futures/openorders' }"
                  >{{ $t("home.header_futures_orders") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/exchange/orders"
                  class="nav-link"
                  :class="{ active: active_tab === '/exchange/orders' }"
                  >{{ $t("home.header_exchange_orders") }}</router-link
                >
              </li>
              <li v-if="sysconfig.bOptionEnabled" class="nav-item">
                <router-link
                  to="/boption/orders"
                  class="nav-link"
                  :class="{ active: active_tab === 'boption/orders' }"
                  >{{ $t("home.header_boption_orders") }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  to="/startup/orders"
                  class="nav-link"
                  :class="{ active: active_tab === 'startup/orders' }"
                  >{{ $t("startup.label_orders") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="list-group-item">
            <ul class="navbar-nav">
              <li class="nav-item" v-for="lang in langs" :key="lang">
                <a
                  class="nav-link"
                  :class="{ disabled: $i18n.locale === lang }"
                  href="javascript:;"
                  @click="changeLang(lang)"
                  >{{ getLocaleName(lang) }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { getAppContext } from "utilities/helper";
import { Offcanvas } from "bootstrap";

export default {
  props: ["readFlag"],
  data() {
    return {
      langs: [],
      context: null,
      bsOffcanvas: null,
      offcanvas_pos: "offcanvas-start",
      active_tab: "/"
    };
  },

  watch: {
    $route(to, from) {
      this.watchNavLinks();
    }
  },

  created() {
    this.context = getAppContext();
    this.langs = Object.freeze(this.sysconfig.supportedLanguages);
    if (this.$version === "4.2" || this.$version === "4.3") {
      this.offcanvas_pos = "offcanvas-end";
    }
  },

  mounted() {
    this.watchNavLinks();
  },

  methods: {
    changeLang: function (lang) {
      $.changeLang(this, lang);
    },

    watchNavLinks: function () {
      // console.log(`## update nav links`);
      this.active_tab = this.$route.path.slice(1);
      // Make sure the offcanvas is created.
      this.bsOffcanvas = Offcanvas.getOrCreateInstance(this.$refs.sideNav);

      // Force closing the offcanvas after a link is clicked.
      const self = this;
      $(this.$refs.sideNav)
        .find("a[data-hooked!='yes']")
        .attr("data-hooked", "yes")
        .on("click", () => {
          // console.log(`## nav link was clicked`);
          self.bsOffcanvas.hide();
        });
    }
  }
};
</script>
<style scoped>
.readFlag {
  position: relative;
  width: 6px;
  height: 6px;
  top: -2px;
  background: red;
  display: inline-block;
  border-radius: 50%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .d-md-none {
    display: flex !important;
  }
}
</style>