<template>
    <div class="mask" v-if="isMaskShow" @click="closeMask" @touchmove.prevent @srcoll.prevent>
        <!-- The contents of the mask layer -->
    </div>
</template>

<script>
export default {
    props: {
        isMaskShow:false,
    },
    data() {
        return {
            // Control the display and hiding of mask layers
            show:true
        };
    },
    methods:{
        closeMask:function(){
            return this.$emit("closeMaskC");
        }
    }
};
</script>

<style scoped>
.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    z-index: 8;
}
</style>