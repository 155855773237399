<template>
    <div class="mask" v-if="show" @touchmove.prevent>
        <!-- The contents of the mask layer -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true // Control the display and hiding of mask layers
        };
    }
};
</script>

<style scoped>
.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
}
</style>