<template>
    <section class="page micro-page">
        <loading-indicator v-if="!symbol || !boptionConfig" />
        <template v-else>
            <content-top ref="contentTop" :symbol="symbol" :boptionConfig="boptionConfig" :boptionOrder="boptionOrder" @create-order="onCreateOrder" />
            <content-bottom ref="contentBottom" :symbol="symbol" :boptionConfig="boptionConfig" @order-completed="onOrderCompleted" />
            <create-order-modal ref="createOrderModal" :symbol="symbol" :boptionOrder="boptionOrder" @order-created="onOrderCreated" @order-completed="onOrderCompleted" />
        </template>
    </section>
</template>

<script>
import ContentBottom from './Components/ContentBottom.vue';
import ContentTop from './Components/ContentTop.vue';
import CreateOrderModal from './Components/BOption_CreateOrder.vue';
import { SymbolInfo } from 'utilities/SymbolInfo.js';
import axios from 'axios';

import { getSymbolManagerAsync } from 'utilities/helper';

export default {
    components: { ContentTop, ContentBottom, CreateOrderModal },

    /**
     * id: the unique id of the exchange pair, e.g. btc_usdt.
     */
    props: ['id'],

    data() {
        return {
            // The current selected symbol.
            symbol: null,

            // The current price of the symbol.
            latestPrice: NaN,

            // The config.
            boptionConfig: null,

            // The new order.
            boptionOrder: null
        };
    },

    watch: {
        $route() {
            this.delayInit(this.id);
        }
    },

    created() {
        this.delayInit(this.id);
    },

    methods: {
        delayInit: function (id) {
            const self = this;

            this.symbol = null;
            setTimeout(() => {
                self.getSymbolAsync(id);
            }, 200);
        },

        getSymbolAsync: async function (id) {
            this.symbol = null;
            this.boptionOrder = null;

            const mgr = await getSymbolManagerAsync();
            let symbol = null;
            if (!id) {
                // Use the first exchange symbol
                let symbols = mgr.getSymbols(1); // 1: binary option symbol
                if (symbols.length > 0) {
                    symbol = symbols[0];
                }
            } else {
                symbol = mgr.getSymbol(id, 1); // 1: binary option symbol
            }

            if (symbol && symbol.type === 1) {
                // read symbol config from server.
                const json = await $.callPostApi(this, '/api/v1/boption/config?id=' + encodeURIComponent(symbol.id));
                if (json && json.errcode === 0 && json.data && id === this.id) {
                    const config = Object.freeze({
                        supportedAmounts: json.data.supportedAmounts,
                        durations: json.data.symbol.durations
                    });

                    this.boptionOrder = new BOptionOrder(config);
                    this.symbol = new SymbolInfo(symbol);
                    this.boptionConfig = config;

                    this.getLatestQuote();
                }
            }
        },

        /**
         * Display a confirmation modal for creating a new order.
         */
        onCreateOrder: function (direction) {
            if (typeof direction !== 'boolean') {
                throw new Error(`Invalid parameter: ${direction}`);
            }

            this.boptionOrder.buyUp = direction;
            this.$refs.createOrderModal.showModal();
        },

        /**
         * Reload open orders when a new order is created.
         */
        onOrderCreated: function () {
            this.$refs.contentBottom.reloadOpenOrders();
        },

        /**
         * Sync balance when an order is completed.
         */
        onOrderCompleted: function (currency) {
            if (currency === 'USDT') {
                this.$refs.contentTop.syncBalance();
            }
        },

        getLatestQuote: function () {
            const sym = this.symbol;
            const sid = sym.metadata.id;
            axios.get('/api/v1/quotation/latest?symbols=' + encodeURIComponent(sid)).then((resp) => {
                if (resp && resp.data) {
                    $(resp.data).each((index, quote) => {
                        if (sid == quote.id) {
                            sym.update(quote);
                        }
                    });
                }
            });
        }
    }
};

function BOptionOrder(boptionConfig) {
    this.amount = '';
    this.duration = NaN;
    this.profitRate = '--';
    this.buyUp = false;
    this.useCoupons = false;

    // Set default value for these fields
    if (boptionConfig.supportedAmounts.length > 0) {
        this.amount = boptionConfig.supportedAmounts[0];
    }
    if (boptionConfig.durations.length > 0) {
        let item = boptionConfig.durations[0];
        this.duration = item.duration;
        this.profitRate = item.profit + '%';
    }

    const self = this;
    this.changeDuration = function (d) {
        const val = d * 1;
        if (Number.isInteger(val)) {
            for (let i = 0; i < boptionConfig.durations.length; i++) {
                let item = boptionConfig.durations[i];
                if (val === item.duration) {
                    self.duration = item.duration;
                    self.profitRate = item.profit + '%';
                    return;
                }
            }
        }

        throw new Error(`Unsupported duration: ${d}.`);
    };
}
</script>