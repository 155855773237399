<template>
    <div class="bottom-nav row d-md-none d-lg-none">
        <router-link class="col" to="/" :class="{ active: active_tab === '/' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#homePage" />
            </svg>
            {{ $t('home.title') }}
        </router-link>
        <router-link class="col" to="/quotes" :class="{ active: active_tab === 'quotes' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#market" />
            </svg>
            {{ $t('home.footer_quotes') }}
        </router-link>
        <router-link class="col" to="/futures" :class="{ active: active_tab === 'trade' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#contract" />
            </svg>
            {{ $t('general.trade') }}
        </router-link>
        <!-- <router-link class="col" to="/startup" :class="{ active: active_tab === 'startup' }">
            <svg class="svg-icon" viewBox="0 0 256 256"><use xlink:href="/dist/svg/icons.svg#icon-rocket" /></svg>
            {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
        </router-link> -->
        <!-- <router-link class="col" to="/user/balance" :class="{ active: active_tab === 'balance' }"><i class="fas fa-wallet"></i> {{ $t('home.footer_assets') }}</router-link> -->
        <router-link class="col" to="/user/balance" :class="{ active: active_tab === 'assets' }">
            <!-- <svg class="svg-icon" viewBox="0 0 256 256"><use xlink:href="/dist/svg/icons.svg#icon-wallet" /></svg> -->
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#wallet-copy" />
            </svg>
            {{ $t('home.footer_assets') }}
        </router-link>
        <router-link class="col" to="/user" :class="{ active: active_tab === 'user' }">
            <svg class="svg-icon" viewBox="0 0 256 256">
                <use xlink:href="/dist/svg/icons.svg#personal" />
            </svg>
            {{ $t('home.footer_personal_settings') }}
        </router-link>
    </div>
</template>

<style scoped>
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .d-md-none  {
        display: flex !important;
    }
}
</style>

<script>
import '@/assets/svg/v4.2/homePage.svg';
import '@/assets/svg/v4.2/market.svg';
import '@/assets/svg/v4.2/contract.svg';
import '@/assets/svg/v4.2/wallet-copy.svg';
import '@/assets/svg/v4.2/personal.svg';

export default {
    props: ['active_tab']
};
</script>