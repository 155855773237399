<template>
    <section class="page home-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols"/>
            <div v-else>
                <!-- major nav icons for sm screens -->
                <div class="d-block d-md-none sm-navs">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <router-link to="/user/login">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#login" />
                                    </svg>
                                    {{ $t('general.login') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <!-- Display a tab for the savings feature when it was enabled. -->
                                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#wallet" />
                                    </svg>
                                    {{ $t('home.header_saving') }}
                                </router-link>
                                <router-link v-else to="/user/balance">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#wallet" />
                                    </svg>
                                    {{ $t(sysconfig.is_hkmy ? 'home.header_buy_crypto_my' : 'home.header_buy_crypto') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/startup">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#Token" />
                                    </svg>
                                    {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/notices">
                                    <div class="svg-box"> <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                                            <use class="use-svg" xlink:href="/dist/svg/icons.svg#notice" />
                                        </svg>
                                    </div>
                                    {{ $t('notices.notice_title') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="home-top">
                    <top-banners v-if="context.homeConfig" :home_config="context.homeConfig" />
                    <!-- <div class="prompted-container">
                        <div class="container">
                            <div class="row">
                                <div class="col">
                                    <home-carousel :symbols="promptedSymbols" @symbol-clicked="gotoTradePage" />
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- top notice -->
                <div class="page-part"
                    v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
                    <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
                </div>

                <!-- symbols -->
                <section class="home-section-quotes">
                    <div class="container quotes-sen">
                        <div class="h22">
                            <h1 class="d-flex justify-content-between">
                                {{ $t("home.market_trend")}}
                            </h1>
                        </div>
                        <div class="main-block-wrapper">
                            <inline-svg-icons :symbols="symbols" />

                            <home-quotes ref="quotes" :symbols="symbols" />

                        </div>
                    </div>
                </section>

                <introduction />
                <!-- advantages introduction -->
                <!-- <features /> -->

                <!-- posts -->
                <!-- <home-posts v-if="homeConfig" :posts="homeConfig.posts" /> -->
                <partner-links v-if="context && context.homeConfig && context.homeConfig.spl === true" />
            </div>
        </section>
    </section>
</template>

<style scoped lang="less">
.home-page .sm-navs .container{
    --bs-gutter-x: 6rem;
}
.home-info-box-bottom{
    margin: 10px 0px 100px 0px;
}
.home-info-bottomImg {
    display: flex;
    justify-content: space-around;
}

.home-info-box {
    display: flex;
    flex-direction: column;

}

.home-info-box-top {
    /* background: url("/src/assets/images/ko-v4.2/top-home-info-bg3.png") repeat right;

    background-size: 100%;
    background-position: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start; */
    display: flex;
    justify-content: space-between;
    background-image: url("/src/assets/images/ko-v4.2/top-home-info-bg.png");
    background-size: 100%;
}

.home-info-img {
    padding: 0px;
    margin: -400px -450px -400px -300px;
}

/* .home-info-behind {
    background: url("/src/assets/images/ko-v4.2/top-home-info-bg.png") no-repeat;
    background-size: auto;
} */

.home-info-text {
    margin: 90px 0px 200px 0px;

}

.home-info-title {
    color: #000922;
    font-size: 40px;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    width: 320px;
    height: 112px;
}

.home-info-desp {
    margin-top: 10px;
    color: #5C6373;
    font-size: 16px;
    font-weight: normal;
    white-space: nowrap;
    color: rgba(64, 71, 90, 1);
    overflow-wrap: break-word;
}

.home-info-link {
    margin-top: 10px;
    background: #4D9EAF;
    border-radius: 2px;
    width: 100px;
    padding: 10px;
    text-align: center
}

/* .home-info {
    background: #fff;
} */

.linkModel {
    display: flex;
    justify-content: center;
}

.h23 {
    font-size: 9px;
    color: black;
    margin: 20px 20px 20px 20px;
}

.h22 h1{
    font-size: 40px;
    flex-direction: column;
    align-items: center;
    color: #000922;
    margin: 20px 0px 20px 0px;
    font-weight: 600;
}

/* quotes背景色 */


.sm-navs {
    /* background: #000;
    color: #fff; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.sm-navs a {
    color: var(--link-color);
}

.sm-navs svg.svg-icon {
    display: block;
    height: 1.8rem;
    width: auto;
    margin: auto;
    margin-bottom: 0.5rem;
    fill: var(--primary-base);
    /* stroke: var(--primary-base); */
}

.svg-box {
    height: 1.5rem;
    position: relative;
    margin-bottom: 0.8rem;
}

.sm-navs svg.svg-icon.notice-svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
@media(max-width: 1024px) {
    .home-info-box-top{
        .home-info-text{
            display: flex;
            flex-direction: column;
            .home-info-link{
                align-self: center;
            }
        }
        
    }
    .h22 h1{
        font-size: 25px;
    }
    
}
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .d-md-none  {
        display: flex !important;
    }
}
</style>

<script type="text/javascript">
// import PostsComponent from './Components/Posts.vue';
import SymbolList from './Components/SymbolList.vue';
import TopBanners from './Components/TopBanners-4.2.vue';
import HomeNotices from './Components/HomeNotices.vue';
import HomePosts from './Components/HomePosts.vue';
// import Features from './Components/Features.vue';
import PromptedSymbols from './Components/PromptedSymbols.vue';
import Introduction from './Components/Introduction-4.2.vue';
import HomeQuotes from './Components/HomeQuotes.vue';
import HomeCarousel from './Components/HomeCarousel.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import PartnerLinks from './Components/_PartnerLinks-4.2.vue';
import HomeLoadingIndicator from './Components/HomeLoadingIndicator.vue';
// import '@/assets/svg/icon-rocket.svg';
// import '@/assets/svg/icon-team.svg';
import '@/assets/svg/icon-login.svg';
import '@/assets/svg/icon-wallet.svg'
import '@/assets/svg/icon-assets.svg';;
// import '@/assets/svg/icon-trust.svg';
// import '@/assets/svg/icon-dollar-coin.svg';
import '@/assets/svg/icon-invite.svg';
import '@/assets/svg/icon-notice.svg';


import '@/assets/svg/v4.2/login.svg';
import '@/assets/svg/v4.2/wallet.svg';
import '@/assets/svg/v4.2/Token.svg';
import '@/assets/svg/v4.2/notice.svg';

import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync, getAppContext } from 'utilities/helper';

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { TopBanners, SymbolList, HomeNotices, HomePosts, Introduction, PromptedSymbols, HomeQuotes, HomeCarousel, InlineSvgIcons, PartnerLinks,HomeLoadingIndicator },

    data() {
        return {
            // All symbols.
            symbols: null,

            // App context
            context: null

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // sort_market: 2,
            // sorted_symbols: null,
            // sort_asc: false
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        }
    },

    created() {
        g_destoryed = false;
        this.context = getAppContext();

        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // this.sortSymbolsAsync(false);
        },

        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        syncQuotes: function () {
            const self = this;

            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // if (this.sorted_symbols) {
            //     $(this.sorted_symbols).each((index, sym) => {
            //         sids.push(sym.metadata.id);
            //     });
            // }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .post('/api/v1/quotation/latest', {symbols: sids.join(',')})
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            // always restart timer
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }

        // UPDATE:
        // Sorted symbols is deprected for now.
        //
        // changeMarket: function (market) {
        //     this.sort_market = market;
        //     this.sortSymbolsAsync();
        // }

        // UPDATE:
        // Sorted symbols is deprected for now.
        //
        // sortSymbolsAsync: async function (asc) {
        //     this.sort_asc = asc;
        //     this.sorted_symbols = null;

        //     const resp = await $.callGetApi(this, '/api/v1/quotation/topsymbols?type=' + encodeURIComponent(this.sort_market) + '&asc=' + (asc ? 'true' : 'false'));
        //     const output = [];
        //     if (Array.isArray(resp)) {
        //         $(resp).each((index, quote) => {
        //             var sym = g_symbolMap[quote.id];
        //             if (sym) {
        //                 sym.update(quote);
        //                 output.push(sym);
        //             }
        //         });
        //     }
        //     this.sorted_symbols = output;
        // }
    }
};
</script>
