<template>
    <section>
        <div class="right-all">
            <div class="top">
                <div>
                    <h3>
                        {{ $t("balance.label_order_type") }}
                    </h3>
                    <button @click="showDepositOrder" class="deposite">{{ $t("general.deposit") }}</button>
                    <button @click="showWithdrawOrder" class="withdraw">{{ $t("general.withdraw") }}</button>
                </div>
                <div>
                    <h3>{{ $t("balance.title_currency")}}</h3>
                    <select name="" id="" v-model="currentID" @change="change($event)" class="selectStyle">
                        <option :value="items.currency" :key="index" v-for="(items, index) in filterRecords">{{
                            items.currency }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-top"></div>
                <div class="bottom-desp">
                    <button @click="cancel" class="cancel">{{ $t("general.cancel") }}</button>
                    <button @click="confirm" class="confirm">{{ $t("general.confirm") }}</button>
                </div>
            </div>

        </div>
    </section>
</template>
<style scoped lang="less">
.selectStyle {
    width: 94%;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #E1E2E6;
}

h3 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 13px;
    color: #111111;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    position: relative;
    margin-top: 20%;
    margin-bottom: 10px;
}

.deposite {
    background: #F1F2F5;
    border-radius: 4px;
    border: 1px solid #4D9EAF;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #4D9EAF;
    line-height: 16px;
    text-align: center;
    padding: 2px 10px;
    font-style: normal;
}

.withdraw {
    background: #F1F2F5;
    border-radius: 4px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #999999;
    line-height: 16px;
    text-align: center;
    padding: 2px 10px;
    font-style: normal;
}

.right-all {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
        padding-left: 1rem;
    }

    .bottom {
        position: absolute;
        top: 70%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .bottom-top {
            width: 90%;
            border-radius: 2px;
            border: 1px solid #E1E2E6;
            margin-bottom: 1.5rem;
            align-self: center;
        }
        .bottom-desp {
            display: flex;
            justify-content: space-between;

            .confirm {
                min-width: 40%;
                height: 2.2rem;
                flex-grow: inherit;
                margin-right: 1rem;
                background: #4D9EAF;
                border-radius: 2px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: center;
                font-style: normal;
            }

            .cancel {
                min-width: 40%;
                flex-grow: inherit;
                margin-left: 1rem;
                background: #FFFFFF;
                border-radius: 2px;
                border: 1px solid #4D9EAF;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #4D9EAF;
                line-height: 20px;
                text-align: center;
                font-style: normal;
            }
        }
    }
}
</style>
<script>
import DatePicker from './DatePicker.vue'
export default {
    data() {
        return {
            currentID: "",
        };
    },
    props: {
        records: null,
        showDeposit: function () { },
        showWithdraw: function () { },
        confirmMsg: function () { },
        cancelCls: function () { },
    },
    components: { DatePicker },
    methods: {
        showDepositOrder: function () {
            return this.$emit("showDeposit");
        },
        showWithdrawOrder: function () {
            return this.$emit("showWithdraw");
        },
        confirm: function () {
            return this.$emit("confirmMsg");
        },
        change(event) {
            this.currentID = event.target.value;
        },
        cancel: function () {
            return this.$emit("cancelCls");
        },
    },
    computed: {
        filterRecords: function () {
            return this.records.filter((item, index, self) => {
                const firstIndex = self.findIndex((obj) => obj.currency === item.currency);
                return index === firstIndex;
            });
        }
    }
}
</script>