<template>
    <i v-if="token" class="d-flex align-items-center token-icon">
        <svg v-html="token.svgIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 70 80"></svg>
    </i>
</template>

<script>
export default {
    props: ['token']
};
</script>