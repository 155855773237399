<template>
    <section class="page settings-page">
        <template v-if="profile">
            <section class="top-header">
                <div class="container">
                    <div class="row align-items-end">
                        <div class="col ">
                            <div class="information">
                                <!-- vip status -->  
                                <div class="real-name">Hi, {{ profile.realName }}</div>
                                <div v-if="profile.vipLevel > 0" class="vip-label">
                                    <img class="vipImage"  :src="vipImage" :alt="`VIP${profile.vipLevel}`"> 
                                </div>
                            </div>

                            <!-- identity verification status -->
                            <div v-if="profile.requiredIDLevel > 0" class="mt-4">
                                <div>{{ $t('user.label_id_verification') }}</div>
                                <div class="fs-5">
                                    <p class="help-block">{{ $t('user.label_id_verification_desp') }}</p>
                                    <p v-if="profile.verifiedLevel >= profile.requiredIDLevel">
                                        <a href="javascript:void(0)" class="fs-3">
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-checked" />
                                            </svg>
                                            {{ $t('user.label_id_verified') }}
                                        </a>
                                    </p>
                                    <p v-else-if="id_status === 1">
                                        <a href="javascript:;" class="fs-3">
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-warning-sign" />
                                            </svg>
                                            {{ $t('user.label_id_waiting') }}
                                        </a>
                                    </p>
                                    <p v-else>
                                        <router-link to="/user/verifyid" class="fs-3">
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-warning-sign" />
                                            </svg>
                                            {{ $t('user.label_id_incomplete') }}
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                            <!-- <span>{{ $t('user.label_register_time') }} {{ new Date(profile.timeCreated).formatDate() }}</span> -->
                        </div>
                    </div>
                </div>
            </section>

            <!-- <div class="page-part">
                <div class="container">
                    <div class="row top-icons">
                        <div class="col">
                            <div>{{ profile.realName }}</div>
                            <div>
                                {{ $t('user.balance_amount') }} <b>{{ balance }}</b> {{ currencySymbol }}
                            </div>
                        </div>
                        <div class="col text-right">
                            <router-link to="/finance/deposit" class="btn btn-primary">{{ $t('user.deposit') }}</router-link>
                            <router-link to="/finance/withdraw" class="btn btn-default">{{ $t('user.withdraw') }}</router-link>
                        </div>
                    </div>
                </div>
            </div> -->

            <section class="w-100">
                <!--
                        TODO:
                        Do we need to display notices.

                        <notice-list-component />
                    -->
                <settings-panel-component v-bind:profile="profile" :jumpRoute="jumpRoute"/>
            </section>
        </template>
        <div v-else>
            <loading-indicator />
        </div>
    </section>
</template>

<style scoped>
.information{
    display: flex;
}
.vip-label{
    margin-left: 10px;
}
.top-header {
    color: var(--top-header-text);
    background: var(--top-header-bg);
    overflow: hidden;
}

.top-header::v-deep .spinner-border {
    border-width: 2px;
    opacity: 0.2;
}

.top-header .svg-icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: currentColor;
}

.top-header .vip-label {
    font-size: 1rem;
    font-weight: 400;
    border-radius: 0.25rem;
}

.icon-warning {
    height: 2rem;
    width: 2rem;
}
.vipImage {
    position: relative;
    width: 100px;
    left: 6px;
    top: 3px;
}
.real-name{
    padding-left: 10px;
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';
import vip1 from '@/assets/images/vip/1.png'
import vip2 from '@/assets/images/vip/2.png'
import vip3 from '@/assets/images/vip/3.png'
import vip4 from '@/assets/images/vip/4.png'
import vip5 from '@/assets/images/vip/5.png'
import vip6 from '@/assets/images/vip/6.png'
import vip7 from '@/assets/images/vip/7.png'
import vip8 from '@/assets/images/vip/8.png'
import vip9 from '@/assets/images/vip/9.png'
import vip10 from '@/assets/images/vip/10.png'
import NoticeListComponent from './Components/Notices.vue';
import SettingsPanelComponent from './Components/SettingsPanel-4.1.vue';
import { setProfile } from 'utilities/helper';

export default {
    components: { NoticeListComponent, SettingsPanelComponent },
    data() {
        return {
            profile: null,
            id_status: NaN,
            jumpRoute: "/user/bankaccounts",
            vipImage:null
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },

    methods: {
        getVipImage(level) {
            if(!level) {
                this.vipImage = null;
            }
            const vipImage = {
                vip1,vip2,vip3,vip4,vip5,vip6,vip7,vip8,vip9,vip10
            }
            if(level>10) {
                this.vipImage = vipImage.vip10
            } else {
                this.vipImage = vipImage['vip' + level]
            }
        },
        async getBindBankStatus(){
            if(this.profile === null) return
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0 && json.data.length === 0) {
                this.jumpRoute =`/user/bankaccount`;
            } else {
                this.jumpRoute = "/user/bankaccounts";
            }
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');
            
            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;
                self.getVipImage(profile.vipLevel);
                self.getBindBankStatus();
            }
        }
    }
};
</script>