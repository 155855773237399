<template>
    <section class="page startup-page support-iframe">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">
            <!-- <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1> -->

            <ul class="page-top-nav mb-3 top-nav-sm">
                <!-- <li :class="{ active: !ongoing_tokens }">
                    <a href="javascript:;" @click="ongoing_tokens = false">{{ $t('startup.title_forecast') }}</a>
                </li> -->
                <li :class="{ active: ongoing_tokens }">
                    <a href="javascript:;" @click="ongoing_tokens = true">{{ $t('startup.title_ongoing') }}</a>
                </li>
                <li>
                    <router-link to="/startup/orders">{{ $t('startup.title_orders') }}</router-link>
                </li>
            </ul>

            <!-- Display a list of tokens -->
            <token-list :ongoing_tokens="ongoing_tokens" />
        </section>

        <ext-resource-i-frame />
    </section>
</template>



<script>
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import TokenList from './Components/TokenList.vue';

export default {
    components: { ExtResourceIFrame, TokenList },

    data() {
        return {
            // Display on-going  or forecast tokens?
            ongoing_tokens: true
        };
    }
};
</script>