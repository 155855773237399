<template>
    <div class="page settings-page">
        <div class="w-100">
            <div class="container">
                <div class="history-title">
                    <div>
                        <router-link to="/user/balance">
                            <img src="@/assets/images/ko-v4.2/go_back.png" alt="">
                        </router-link>
                    </div>
                    <div>
                        <h1>{{ $t('balance.orders_title') }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <orders-component />
                </div>
            </div>
        </div>

    </div>
</template>
<style scoped lang="less">
.w-100 {
    background-color: #F5F5F7;
}

.history-title {
    /* background: #F5F5F7; */
    display: flex;
    align-items: center;

    h1 {
        font-family: PingFangSC, PingFang SC;
        height: 46px;
    }

    img {
        height: 35px;
    }
}

@media(max-width:1400px) {
    .container {
        max-width: 93%;
    }
}

@media(max-width: 1024px) {
    .svg-icon-history {
        height: 1.3rem;
    }

    .w-100 {
        background-color: #fff;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
    }

    .container {
        --bs-gutter-x: 0rem;
    }

    .history-title {
        display: flex;
        background: #fff;
        justify-content: space-between;

        img {
            height: 18px;
        }

        h1 {
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #111111;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            margin-left: 0rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .history-title>div:first-child {
        display: none;
    }
}
</style>
<script>
import OrdersComponent from './Components/Orders.vue';
export default {
    components: { OrdersComponent }
};
</script>