<template>
    <div>
        <!-- Fiat deposit description -->
        <div v-if="fiat_desp && fiat_desp.length">
            <div class="alert alert-info">
                <pre style="font-size: 1rem; line-height: 1.5; white-space: pre-wrap; overflow: auto">{{ get_fiat_desp($i18n.locale) }}</pre>
                <a href="javascript:;" onclick="toggle_udesk()" class="toggle-cs">
                    <svg viewBox="0 0 256 256" class="svg-icon">
                        <use xlink:href="/dist/svg/icons.svg#icon-phone" />
                    </svg>
                    {{ $t('general.customer_service_long') }}
                </a>
            </div>
        </div>

        <!-- Special case for USDT -->
        <div class="form-group">
            <div class="form-label"><b>{{ $t('balance.assets_deposit_address') }} ({{ active_currency }})</b></div>
            <div v-if="coinConfig.currency === 'USDT'">
                <div class="row">
                    <div class="col-md-4">
                        <select class="form-select" v-model="active_currency">
                            <option value="USDT">USDT-ERC20</option>
                            <option value="USDT_TRC">USDT-TRC20</option>
                        </select>
                    </div>
                </div>
                <!-- <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" href="javascript:;" v-bind:class="{ active: active_currency === 'USDT' }" @click="toggle_deposit('USDT')"> USDT-ERC20 </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:;" v-bind:class="{ active: active_currency === 'USDT_TRC' }" @click="toggle_deposit('USDT_TRC')"> USDT-TRC20 </a>
                </li>
            </ul> -->
            </div>
        </div>

        <!-- Loading -->
        <loading-indicator v-if="!deposit_address_loaded" />
        <div v-else>
            <div v-if="deposit_address_error">
                <div class="alert alert-danger small">
                    {{ deposit_address_error }}
                </div>
            </div>
            <div v-else class="deposit-address">
                <div>
                    <span id="deposit-addr">{{ deposit_addr }}</span>

                    <!-- Copy deposit address by calling clipboard.writeText -->
                    <a v-if="use_writeText" @click="writeDepositAddrToClipboard" href="javascript:;" class="link-secondary copy-btn">{{ $t('general.copy') }}</a>

                    <!-- If writeText isn't supported, then change to use tranditional method -->
                    <a v-else ref="copyLink" href="javascript:;" data-target="#deposit-addr" class="link-secondary copy-btn">{{ $t('general.copy') }}</a>
                </div>
                <img v-bind:src="deposit_qr" alt="QR Code" v-if="show_qr" v-on:click="toggle_qr()" />
                <a href="javascript:;" v-on:click="toggle_qr()" class="link-secondary">{{ $t('balance.assets_deposit_qr') }}</a>
            </div>
        </div>
        <div class="help-block">
            <!-- deposit tips -->
            <div class="small" v-html="$t('balance.assets_deposit_tips', { ext_name: coinConfig.currency, min_deposit_amount: coinConfig.minDepositAmount })"></div>
        </div>
    </div>
</template>
<style scoped>
.copy-btn {
    border: 1px solid #4d9eaf;
    background: #4d9eaf;
    font-weight: normal;
    white-space: nowrap;
    color: #f2f2f2 !important;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    display: inline-block;
    min-width: 60px;
}
</style>
<script>
export default {
    props: ['bcConfig', 'coinConfig'],

    data() {
        return {
            active_currency: this.coinConfig.currency === 'USDT' ? 'USDT_TRC' : this.coinConfig.currency,

            deposit_address_loaded: false,
            deposit_address_error: null,
            deposit_addr: null,
            deposit_qr: null,
            show_qr: false,

            // The default desp text for fiat deposits.
            fiat_desp: null,

            use_writeText: false
        };
    },

    watch: {
        active_currency: function () {
            console.log(`active_currency is changed to ${this.active_currency}`);
            this.toggle_deposit(this.active_currency);
        }
    },

    created() {
        // Special case for USDT.
        let token = this.coinConfig.currency;
        if (token === 'USDT')
            token = 'USDT_TRC';

        // Save the default description text for fiat deposits.
        this.fiat_desp = this.bcConfig.fiatDescription;

        this.toggle_deposit(token);

        // Is the writeText supported by the browser?
        this.use_writeText = navigator && navigator.clipboard && typeof navigator.clipboard.writeText === 'function';
    },

    methods: {
        writeDepositAddrToClipboard: function () {
            try {
                navigator.clipboard.writeText(this.deposit_addr).then(
                    () => {
                        // succeeded
                        $.top_alert($.getLocaleMessage('general.copied'), false);
                    },
                    (err) => {
                        // failed
                        console.log('Copy failed: ' + err);

                        // Try to use another method
                        let textArea = document.createElement("textarea");
                        textArea.value = this.deposit_addr;

                        // Make the TextArea invisible.
                        textArea.style.position = "absolute";
                        textArea.style.opacity = 0;
                        textArea.style.left = "-999999px";
                        textArea.style.top = "-999999px";
                        textArea.style.height = '10px';
                        document.body.appendChild(textArea);
                        textArea.focus();
                        textArea.select();

                        let pasted = document.execCommand('copy');
                        textArea.remove();

                        if (pasted) {
                            $.top_alert($.getLocaleMessage('general.copied'), false);
                        } else {
                            $.top_error('Copy failed.');
                        }
                    });
            } catch (err) {
                $.top_error('Copy failed: ' + err);
            }
        },

        toggle_deposit: function (target_currency) {
            // if (this.active_currency === target_currency) {
            //     return;
            // }

            this.active_currency = target_currency;
            this.deposit_address_loaded = false;

            // query deposit address from server
            $.callPostApi(this, '/api/v1/blockchain/get_coin_address?coin=' + encodeURIComponent(target_currency))
                .then((json) => {
                    if (json.errcode === 0) {
                        this.deposit_addr = json.data.address;
                        this.deposit_qr = json.data.qr;
                        this.deposit_address_error = null;
                    } else {
                        this.deposit_address_error = json.errmsg;
                    }
                    this.deposit_address_loaded = true;

                    // Enable the copy link
                    if (!this.use_writeText) {
                        const self = this;
                        Vue.nextTick(() => {
                            $(self.$refs.copyLink).enable_copy();
                        });
                    }
                })
                .catch((err) => { });
        },

        toggle_qr: function () {
            this.show_qr = !this.show_qr;
        },

        get_fiat_desp: function (lang) {
            if (typeof lang === 'string' && lang.length > 0) {
                // The language key will always be in lower case.
                return ((this.bcConfig && this.bcConfig.fiatDesps) ? this.bcConfig.fiatDesps[lang.toLowerCase()] : null) || this.fiat_desp;
            }

            // Return the default text.
            return this.fiat_desp;
        }
    }
};
</script>
<style scoped>
.alert-info pre{
    color: #000;
}
</style>