<template>
    <section>
        <table class="table list-table stock-list-table history-table">
            <thead>
                <tr>
                    <th>{{ $t('balance.title_currency') }}</th>
                    <th>{{ $t('balance.title_time') }}</th>
                    <th>{{ $t('balance.label_order_amount') }}</th>
                    <th>{{ $t('balance.title_balance') }}</th>
                    <th class="text-right">{{ $t('balance.title_frozen') }}</th>
                    <th>{{ $t('balance.title_description') }}</th>
                </tr>
            </thead>
            <tbody v-if="records && records.length > 0">
                <tr v-for="rec in records" v-bind:key="rec.id">
                    <td>{{ getTokenName(rec.currency) }}</td>
                    <td>{{ new Date(rec.timeCreated).formatDateTime() }}</td>
                    <td>
                        <div v-if="rec.balanceChange != 0"
                            :class="{ 'color-up': rec.balanceChange > 0, 'color-down': rec.balanceChange < 0 }">
                            {{ rec.balanceChange > 0 ? '+' + rec.balanceChange : rec.balanceChange }}
                        </div>
                    </td>
                    <td>
                        <div>{{ rec.currentBalance }}</div>
                    </td>
                    <td class="text-right">
                        <div>{{ rec.currentFrozen }}</div>
                        <div v-if="rec.frozenChange != 0"
                            :class="{ 'color-up': rec.frozenChange > 0, 'color-down': rec.frozenChange < 0 }">
                            {{ rec.frozenChange > 0 ? '+' + rec.frozenChange : rec.frozenChange }}
                        </div>
                    </td>
                    <!--
                    It was asked to display the original description text for deposit records.
                    20: normal deposits
                    26: manual deposits
                    -->
                    <td>{{ rec.description && (rec.type === 20 || rec.type === 26) ? rec.description :
                        translate(rec.type, rec.description) }}</td>
                </tr>
            </tbody>
        </table>
        <table class="mobile-table">
            <tbody v-if="records && records.length > 0">
                <tr v-for="rec in records" v-bind:key="rec.id">
                    <div class="tr-left">
                        <div>
                            <div v-if="rec.balanceChange >= 0">
                                <svg viewBox="0 0 256 256" class="svg-icon-history">
                                    <use xlink:href="/dist/svg/icons.svg#oval" />
                                </svg>
                            </div>
                            <div v-if="rec.balanceChange < 0">
                                <svg viewBox="0 0 256 256" class="svg-icon-history">
                                    <use xlink:href="/dist/svg/icons.svg#oval2" />
                                </svg>
                            </div>
                        </div>
                        <div class="tr-left-right">
                            <td>{{ getTokenName(rec.currency) }}</td>
                            <td>{{ new Date(rec.timeCreated).formatDateTime() }}</td>
                        </div>
                    </div>

                    <div class="tr-right">
                        <div class="tr-right-top">
                            <td>
                                <div v-if="rec.balanceChange != 0"
                                    :class="{ 'color-up': rec.balanceChange >= 0, 'color-down': rec.balanceChange < 0 }">
                                    {{ rec.balanceChange > 0 ? '+' + parseFloat(rec.balanceChange).toFixed(2) : parseFloat(rec.balanceChange).toFixed(2) }}{{ ' USDT'
                                    }}
                                </div>
                                <div v-else>
                                    {{ "+-0" }}{{ ' USDT' }}
                                </div>
                            </td>
                        </div>
                        <div class="tr-right-bottom">
                            <div class="bottom-t">
                                <td>{{ $t('balance.title_frozen')+': ' }}</td>
                                <td class="d-flex">
                                    <div>{{ rec.currentFrozen }}</div>
                                    <div v-if="rec.frozenChange != 0"
                                        :class="{ 'color-up': rec.frozenChange > 0, 'color-down': rec.frozenChange < 0 }">
                                        {{ rec.frozenChange > 0 ? '+' + rec.frozenChange : rec.frozenChange }}
                                    </div>
                                </td>
                            </div>
                            <div class="bottom-d">
                                <td>{{ $t('balance.title_description')+': ' }}</td>
                                <td>{{ rec.description && (rec.type === 20 || rec.type === 26) ? rec.description :
                                    translate(rec.type, rec.description) }}
                                </td>
                            </div>
                        </div>
                    </div>
                </tr>
            </tbody>
            <div v-if="isShow">
                <!-- child->father -->
                <!-- <filter-model v-if="isShowFilterModel" class="top-float" :records="records" :isShowFilterModel="isShowFilterModel" @hiddenFilter="hideFilters"></filter-model> -->
                <!-- grandson->grandpa -->
                <filter-model v-if="isShowFilterModel" class="top-float" :records="records"
                    :showGlobalMask="showGlobalMask"></filter-model>
            </div>
        </table>
        <loading-indicator v-if="!records" />
        <div v-else-if="records.length === 0" class="no-data-indicator m-5">{{ $t('general.no_data') }}</div>
        <div v-else class="go-next-page">
            <button v-if="prevUids.length > 0" class="btn btn-default text-muted" v-on:click="goPrevPageAsync()">{{
                $t('balance.label_prev_page') }}</button>
            <button v-if="hasMoreRecords" class="btn btn-default text-muted" v-on:click="goNextPageAsync()">{{
                $t('balance.label_next_page') }}</button>
        </div>
    </section>
</template>

<style scoped>
.top-float {
    position: fixed;
    height: 100%;
    width: 100%;
    right: 0rem;
    background: #fff;
    bottom: 0;
    z-index: 3;
}

.btn {
    margin: 1rem 0;
}

.table td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    color: #000922;
}

.table td:nth-child(3),
.table td:nth-child(6) {
    color: #111111;
}

.table th {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    color: #7F8490;
    font-weight: 400;
    font-style: normal;
    font-family: PingFangSC, PingFang SC;
}

.table>:not(caption)>*>* {
    background-color: #fff;
}

.color-deposit {
    color: #00B481;
}

.color-withdraw {
    color: #F45A50;
}

/* svg-icon-style */
.svg-icon-history {
    height: 3rem;
    margin-right: .5rem;
    margin-bottom: 1rem;
}
</style>

<script>
import "@/assets/svg/ko-v4.2/oval.svg"
import "@/assets/svg/ko-v4.2/oval2.svg"
import FilterModel from "../Components/FilterModel.vue";
const bct_map = {};

export default {
    data() {
        return {
            records: null,
            hasMoreRecords: false,
            until_uid: 0,

            // An array to store starting uids for previous pages.
            prevUids: [],

            // The current language code.
            lang: null,
        };
    },
    props: { isShowFilterModel: false, showGlobalMask: false, isShow: false },
    components: { FilterModel },
    watch: {
        '$i18n.locale': function () {
            // Always use lower case to read customized balance change types.
            this.lang = (this.$i18n.locale || 'en').toLowerCase();
        }
    },
    created() {
        // Remember all balance change types.
        $(this.sysconfig.bcts).each((_, val) => {
            bct_map[val] = true;
        });

        // Start to query records from server.
        this.goNextPageAsync();

        // Always use lower case to read customized balance change types.
        this.lang = (this.$i18n.locale || 'en').toLowerCase();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////////
        // Query records
        ///////////////////////////////////////////////////////////////////////////////////////////
        goNextPageAsync: async function () {
            // Remember the id of the first record in the current page.
            if (this.records && this.records.length > 0) {
                this.prevUids.push(this.records[0].uid + 1); // +1: because the query will exclude the specified uid itself.
            }

            this.records = null;
            try {
                const uid = this.until_uid;
                const json = await $.callPostApi(this, '/api/v1/finance/history?uid=' + encodeURIComponent(uid));
                this.records = json.data;
                if (json.data && json.data.length) {
                    this.until_uid = json.data[json.data.length - 1].uid;
                }
                this.hasMoreRecords = json.data.length === json.page_size;
            } catch (err) {
                console.error(`ERROR: ${err}`);
                $.top_error(this.$t('general.http_error'));
            }
        },

        goPrevPageAsync: async function () {
            if (this.prevUids.length > 0) {
                this.records = null;

                const uid = this.prevUids.pop();
                if (!isNaN(uid) && uid > 0) {
                    try {
                        console.log(`### start from ${uid}`);
                        const json = await $.callPostApi(this, '/api/v1/finance/history?uid=' + encodeURIComponent(uid));
                        this.records = json.data;
                        if (json.data && json.data.length) {
                            this.until_uid = json.data[json.data.length - 1].uid;
                        }
                        this.hasMoreRecords = json.data.slength === json.page_size;
                    } catch (err) {
                        console.error(`ERROR: ${err}`);
                        $.top_error(this.$t('general.http_error'));
                    }
                }
            }
        },

        getTokenName: function (token) {
            switch (token) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token;
            }
        },

        translate: function (type, desp) {
            if (type < 100) {
                return this.$t(bct_map[type] === true ? 'bct.' + type : 'bct.others');
            } else {
                return (this.sysconfig.customBcts[this.lang] || {})[type] || desp;
            }
        },
        // child->father

        // hideFilters:function(){
        //     this.isShowFilterModel=false;
        // }
    }
};
</script>