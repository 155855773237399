<template>
    <div id="__layout">
        <section class="main" :class="{ 'trade-layout': trade_layout, 'dark-layout': dark_bg, 'region-ex': is_region_ext }">
            <!-- page header -->
            <page-header :active_tab="active_tab" :active_tab_lg="active_tab_lg" />

            <!-- main content -->
            <transition v-bind:name="transition_name">
                <router-view></router-view>
            </transition>

            <!-- page footer -->
            <page-footer-slim v-if="slim_footer" />
            <template v-else>
                <div v-if="trade_layout" class="trade-footer">
                    <page-footer />
                </div>
                <page-footer v-else />
            </template>

            <!-- bottom nav -->
            <bottom-nav v-if="!trade_layout" :active_tab="active_tab" />
        </section>

        <!-- Display alert/error messages -->
        <div ref="toast" class="toast hide" data-delay="2500">
            <div class="toast-body"></div>
        </div>
    </div>
</template>

<style type="text/css">
/**
     * toast (always display at page top)
     */
/* in bootstrap v4, the z-index of modals is 1050. We have to use a bigger z-index value for toasts. */
.toast {
    position: fixed;
    top: 0.5rem;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1060;
    border-radius: 0;
    max-width: 600px;
    width: 100%;
    background-color: #EEF5F7;
    border-color: #EEF5F7;
    border-radius: 0.5rem;
    color: #4D9EAF;
}

.toast .toast-body {
    font-size: 1.2rem;
    line-height: 1.5;
}

.toast.has-error {
    color: #4D9EAF;
    background-color: #EEF5F7;
    border-color: #fff;
}

.toast .btn-close {
    padding-top: 1rem;
    padding-right: 1rem;
}

@media (max-width: 1024px) {
    .toast {
        top: 0;
        min-width: 100%;
        border-radius: 0;
    }
}
</style>

<script type="text/javascript">
const tabMap_sm = {};
tabMap_sm['/'] = '/';
tabMap_sm['/kn'] = 'kn';
tabMap_sm['/boption'] = 'trade';
tabMap_sm['/exchange'] = 'trade';
tabMap_sm['/futures'] = 'trade';
tabMap_sm['/user/balance'] = 'assets';
tabMap_sm['/user'] = 'user';
tabMap_sm['/finance'] = 'user';
const tabMap_lg = {};
tabMap_lg['/user/balance'] = 'assets';

import PageHeader from './Components/PageHeader-4.2.vue';
import PageFooter from './Components/PageFooter-4.1.vue';
import PageFooterSlim from './Components/PageFooterSlim.vue';
import BottomNav from './Components/BottomNav-4.2.vue';

import { getHomeConfigAsync } from 'utilities/helper';
import { Toast } from 'bootstrap';

export default {
    components: { PageHeader, PageFooter, PageFooterSlim, BottomNav },

    data() {
        return {
            transition_name: 'slide-left',
            active_tab: '/',
            active_tab_lg: '/',
            slim_footer: false,
            trade_layout: false,
            dark_bg: false,
            is_region_ext: false,

            // The toast element at page top.
            msg_toast: null
        };
    },

    created() {
        // Use the ex-region for my, and trc platform.
        this.is_region_ext = this.sysconfig.region === 'my' || this.sysconfig.name.toLowerCase().indexOf('trc') === 0;

        this.update_route(this.$route);

        // Start to read home config.
        getHomeConfigAsync(this.$i18n.locale);
    },

    mounted() {
        this.msg_toast = new Toast(this.$refs.toast);
        const self = this;

        $.extend({
            /**
             * display an message at page top
             * @param {any} msg The message to display
             * @param {any} is_error Indicates whether this is an error message
             */
            top_alert: function (msg, is_error) {
                $('.toast .toast-body').text(msg);
                $('.toast').toggleClass('has-error', !!is_error); // .toast('show');

                self.msg_toast.show();
            },


            /**
             * display error message at page top
             * @param {any} msg The error message.
             */
            top_error: function (msg) {
                $.top_alert(msg, true);
            }
        });
    },

    methods: {
        is_active: function (tab) {
            return this.active_tab === tab;
        },

        update_route: function (route) {
            this.slim_footer = !!route.meta.slimFooter;
            this.trade_layout = !!route.meta.tradeLayout;
            this.dark_bg = !!route.meta.darkLayout;

            // Update navigation bars
            {
                let path = route.path;
                if (path.length === 0) {
                    this.active_tab = this.active_tab_lg = '/';
                } else {
                    const tab = tabMap_sm[path];
                    let pos = path.indexOf('/', 1);
                    const PAGE = (pos > 0 ? path.substring(0, pos) : path).toLowerCase();

                    this.active_tab = tabMap_sm[path] || tabMap_sm[PAGE] || PAGE.substring(1);
                    this.active_tab_lg = tabMap_lg[path] || tabMap_lg[PAGE] || PAGE.substring(1);
                }
            }

            // Is the language explicitly specified?
            let lang = route.query.lang;
            if (lang && typeof lang === 'string' && lang.length > 0) {
                try {
                    $.changeLang(this, lang);
                } catch (err) {
                    console.error(`Failed to change language: ${err}`);
                }
            }
        }
    },

    watch: {
        $route(to, from) {
            this.update_route(to);

            // https://router.vuejs.org/guide/advanced/transitions.html#per-route-transition
            //
            const toDepth = to.path.split('/').length;
            const fromDepth = from.path.split('/').length;
            this.transition_name = toDepth < fromDepth ? 'slide-right' : 'slide-left';

             // Re-fetch home-config after log-in
            if (to.path === '/'){
                getHomeConfigAsync(this.$i18n.locale);
            }
        },

        '$i18n.locale': function (to_val, from_val) {
            getHomeConfigAsync(to_val);
        }
    }
};
</script>