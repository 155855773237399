<template>
    <div>
        <div ref="earnModal" class="modal fade my-modal earn-dialog" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="title justify-content-between">
                        <div>{{ $t('coin_treasure.subscription') }} {{ selectRowData.token }}</div>
                        <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="content">
                        <div class="earn-left">
                            <div class="day-type">
                                <p class="day-title mb-2">{{ $t('coin_treasure.term_days') }}</p>
                                <div class="day-list">
                                    <div v-for="(i, k) in selectRowData.interestRatesList" :key="k" class="day-li" :class="{ 'active': i.active === true }" @click="selectDay(k)">
                                        <span class="text">{{ i.day == '0' ? $t('coin_treasure.current') : i.day }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-box">
                                <p class="label">{{ $t('coin_treasure.subscription_amount') }}</p>
                                <div class="input-group">
                                    <input type="number" v-model="amount" class="form-control input" placeholder="">
                                    <span class="input-group-btn" @click="setMax">{{ $t('coin_treasure.maximum') }}
                                    </span>
                                </div>
                                <div class="error-tip" v-if="errorTip">{{ errorTip }}</div>
                                <div class="bottom">
                                    <div class="balance">
                                        <div class="coin-text">{{ balance }}<span>USDT</span>
                                        </div>
                                        <div> {{ $t('coin_treasure.available') }}</div>
                                    </div>
                                    <!-- <button type="button" class="btn btn-default" data-bs-dismiss="modal">
                                    <router-link to="/exchange/" + {{ selectRowData.token }}>{{$t('coin_treasure.buy_coins')}}</router-link>
                                </button> -->
                                </div>
                                <div class="amount-scope">
                                    <div class="p">{{ $t('coin_treasure.restrictions') }}</div>
                                    <div class="amount">
                                        <span class="min" v-if="selectRowData.minAmount > 0"> {{ $t('coin_treasure.minimum') }}: {{ selectRowData.minAmount }} USDT</span>
                                        <span>{{ $t('coin_treasure.maxmum') }}: {{ selectRowData.maxAmount }} USDT</span>
                                    </div>
                                </div>
                                <div class="interest-tip tip">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-va7khm">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="currentColor"></path>
                                        </svg>
                                        <p>{{ $t('coin_treasure.annualized_tip') }}</p>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-va7khm">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zM10.75 8.5V6h2.5v2.5h-2.5zm0 9.5v-7h2.5v7h-2.5z" fill="currentColor"></path>
                                        </svg>
                                        <p>{{ $t('coin_treasure.earnings_tip') }}</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="earn-right">
                            <div style="flex:1">
                                <div class="time-title">{{ $t('coin_treasure.overview') }}</div>
                                <div class="time-line">
                                    <div class="box">
                                        <div class="line">
                                            <span class="c"></span>
                                            <span class="l"></span>
                                        </div>
                                        <div class="time">
                                            <div class="label">{{ $t('coin_treasure.subscription_date') }}</div>
                                            <div class="t">{{ new Date().formatDate() }}</div>
                                        </div>
                                    </div>
                                    <div class="box">
                                        <div class="line">
                                            <span class="c"></span>
                                            <span class="l"></span>
                                        </div>
                                        <div class="time">
                                            <div class="label">{{ $t('coin_treasure.value_date') }}</div>
                                            <div class="t">{{ new Date(new Date().getTime() + (1 * 60 * 60 * 24 * 1000)).formatDate() }}</div>
                                        </div>
                                    </div>
                                    <div class="box" v-if="numDays > 0">
                                        <div class="line">
                                            <span class="c"></span>
                                            <span class="l"></span>
                                        </div>
                                        <div class="time">
                                            <div class="label">{{ $t('coin_treasure.last_payment_date') }}</div>
                                            <div class="t">{{ new Date(new Date().getTime() + (numDays * 60 * 60 * 24 * 1000)).formatDate() }}</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="interest-rates">
                                    <div class="rates">
                                        <div>{{ $t('coin_treasure.estimated_rate') }}</div>
                                        <div class="r" :class="interestRates >= 0 ? 'up' : 'down'">{{ Math.round(interestRates * 10000) / 100 }}%</div>
                                    </div>
                                    <div class="income" v-if="selectRowData.preToken === selectRowData.token">
                                        <div>{{ $t('follow.estimated_profit') }}</div>
                                        <div class="r" :class="interestRates >= 0 ? 'up' : 'down'">
                                            {{ calcInterestRates + selectRowData.token }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div></div>
                            <div class="submit">
                                <label class="checkbox" for="earn-checkbox">
                                    <input class="input" type="checkbox" v-model="checkedStatus" id="earn-checkbox" />
                                    <div>
                                        {{ $t('coin_treasure.read_agree') }}
                                        <div style="display:inline" data-bs-dismiss="modal">
                                            <a href="javascript:;" class="link" @click="showDialog">{{ $t('coin_treasure.cunbibao_service', { name: sysconfig.name }) }}</a>
                                        </div>
                                    </div>
                                </label>
                                <button :class="{ 'btn-loading': submitting }" :disabled="!checkedStatus || !(amount > 0) || errorTip" class="btn btn-primary" @click="createOrder">{{ $t('coin_treasure.confirm_subscription') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PopupHelp ref="popupHelp" />

    </div>
</template>

<script>
import { savingCreate } from '../request'
import PopupHelp from './PopupHelp.vue';
import { Modal } from 'bootstrap';

export default {
    components: { PopupHelp },

    props: {
        selectRowData: {
            type: Object,
            default: () => { }
        },
        balance: {
            default: 0
        }
    },

    data() {
        return {
            checkedStatus: false,
            amount: '',
            interestRates: '',
            numDays: '',
            calcInterestRates: '--',
            submitting: false,
            minAmount: 0,
            maxAmount: 0,
            errorTip: false,
            isShowPopupHelp: false,

            // The popup confirmation modal.
            earnModal: null
        };
    },

    watch: {
        selectRowData() {
            this.amount = ''
            setTimeout(() => {
                this.errorTip = false
            }, 200)
            this.getPageValue()
        },
        amount(n, o) {
            const { minAmount, maxAmount } = this.selectRowData
            if (n > this.balance) {
                this.errorTip = this.$t('coin_treasure.error_tip')
            } else if (n > maxAmount || n < minAmount) {
                this.errorTip = this.$t('coin_treasure.error_tip_two')
            } else {
                this.errorTip = false
            }
            this.getPageValue()
        },
    },
    mounted() {
        this.earnModal = new Modal(this.$refs.earnModal);
        this.getPageValue()
    },

    beforeDestroy() {
        const m = this.earnModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        showDialog() {

            const modal = this.$refs.popupHelp
            modal.showModal();
        },
        createOrder() {
            if (this.submitting) {
                return false
            }
            let numDays = 0
            this.selectRowData.interestRatesList.forEach(item => {
                if (item.active) {
                    numDays = item.day
                }
            })
            let data = {
                amount: this.amount,
                id: this.selectRowData.id,
                numDays: numDays
            }
            this.submitting = true
            savingCreate(data).then(res => {
                this.submitting = false
                this.amount = ''
                this.checkedStatus = false
                if (res.data.errcode === 0) {
                    $.top_alert(this.$t('general.operation_succeeded'));
                    // this.$refs.earnModal.click()
                } else {
                    $.top_error(res.data.errmsg);
                }
                // $(this.$refs.earnModal).modal('hide');
                this.earnModal.hide();
            })
            // this.parentfunc() 
        },
        showModal() {
            // const modal = bootstrap.Modal.getOrCreateInstance(this.$refs.earnModal);
            // modal.show();
            this.earnModal.show();
        },

        selectDay(k) {
            let selectData = this.selectRowData
            selectData.interestRatesList.forEach(item => {
                item.active = false
            })
            selectData.interestRatesList[k].active = true
            this.interestRates = selectData.interestRatesList[k].value
            this.selectRowData = selectData
            this.getPageValue()
            // this.$set(this.selectRowData.interestRatesList,k,selectData.interestRatesList[k])

        },
        setMax() {
            this.amount = this.balance
        },

        incomeFunc() {
            return '--' + this.selectRowData.token
        },
        getPageValue() {
            let numDays = 0
            let interestRates = 0
            this.selectRowData.interestRatesList.forEach(item => {
                if (item.active) {
                    interestRates = item.value
                    numDays = item.day
                }
            })
            this.interestRates = interestRates
            this.numDays = numDays
            if (this.amount) {
                if (this.numDays > 0) {
                    console.log((interestRates / 365 * numDays).toFixed(4));
                    this.calcInterestRates = ((interestRates / 365 * numDays) * this.amount).toFixed(6)
                } else {
                    this.calcInterestRates = ((interestRates / 365) * this.amount).toFixed(6)
                }
            } else {
                this.calcInterestRates = '--'
            }
            // return {
            //     amount: this.amount,
            //     id: this.selectRowData.id,
            //     interestRates,
            //     numDays
            // }
        },

    }
};
</script>
