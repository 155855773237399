<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('register.label_phone') }}</h1>
                    <div class="text-muted">{{ $t('user.phone_link_desp') }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col"><loading-indicator v-if="!profile" />
                    <div v-else-if="profile.phone" class="py-3">
                        {{ $t('user.phone_linked', { phone: profile.phone }) }}
                    </div>
                    <div v-else class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <edit-phone-component :profile="profile" :regions="regions" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import EditPhoneComponent from './Components/_EditPhone.vue';
import { setProfile } from 'utilities/helper';

export default {
    components: { EditPhoneComponent },
    data() {
        return {
            profile: null,
            // All supported regions
            regions: null
        }
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    mounted() {
        this.initAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },

    methods: {
        initAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                let resp = await $.callGetApi(self, '/api/v1/config/regions');
                if (resp) {
                    self.regions = resp.data || [];
                    self.profile = profile;
                }
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
}
</script>