<template>
  <div class="w-100">
    <div class="loading-log my-5">
      <div class="three-bounce">
        <div class="img">
          <img src="/favicon_128x128.png" alt="">
        </div>
        <div v-for="item in nameList" :key="item.key" :class="item.key">
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      nameList: []
    };
  },

  created() {
    if (this.sysconfig.name) {
      let name = this.sysconfig.name.split('');
      this.nameList = [];
      name.forEach((item, index) => {
        let row = { key: 'item' + index, value: item };
        this.nameList.push(row);
      })
    }
  },
}
</script>
<style scoped>
.loading-log {
  position: relative;
  width: 100%;
  height: 4rem;
}

.loading-log .three-bounce {
  position: relative;
  text-align: center;
  top: 50%;
  bottom: 50%;
  margin-top: -0.75rem;
}

.loading-log .three-bounce>div {
  font-size: 30px;
  color: #000;
  display: inline-block;
  border-radius: 100%;
  top: 50%;
  margin-top: -0.75rem;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin-right: 4px;
}

.loading-log .three-bounce img {
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
}

.quotes-page .loading-log .three-bounce>div,
.login-page .loading-log .three-bounce>div {
  background: #000;
}

.loading-log .spinner-sm .three-bounce>div {
  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
}

.loading-log .three-bounce .img {
  -webkit-animation-delay: -0.72s;
  animation-delay: -0.72s;
}

.loading-log .three-bounce .item0 {
  -webkit-animation-delay: -0.63s;
  animation-delay: -0.63s;
}

.loading-log .three-bounce .item1 {
  -webkit-animation-delay: -0.54s;
  animation-delay: -0.54s;
}

.loading-log .three-bounce .item2 {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.loading-log .three-bounce .item3 {
  -webkit-animation-delay: -0.36s;
  animation-delay: -0.36s;
}

.loading-log .three-bounce .item4 {
  -webkit-animation-delay: -0.27s;
  animation-delay: -0.27s;
}

.loading-log .three-bounce .item5 {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.loading-log .three-bounce .item6 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@keyframes bouncedelay {

  0%,
  100%,
  80% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>