<template>
    <section>
        <table class="table compact startup-table">
            <thead class="display-none">
                <tr>
                    <th>{{ $t('startup.label_token') }}</th>
                    <th class="d-none d-md-table-cell">{{ $t('startup.orders_start_time') }}</th>
                    <th class="d-none d-md-table-cell">{{ $t('startup.orders_end_time') }}</th>
                    <!-- <th class="d-none d-md-table-cell">{{ $t('startup.label_grant_date') }}</th> -->
                    <th v-if="ongoing_tokens" class="label-progress-thead">{{ $t('startup.label_progress') }}</th>
                    <th>{{ $t('startup.label_target_amount') }}</th>
                    <th>{{ $t('startup.label_offer_price') }}</th>
                    <th :class="ongoing_tokens ? 'd-none d-md-table-cell' : ''">{{ $t('startup.label_max_sub') }}</th>
                    <th :class="ongoing_tokens ? 'd-none d-md-table-cell' : ''">{{ $t('startup.label_min_sub') }}</th>
                    <th v-if="ongoing_tokens"></th>
                </tr>
            </thead>
            <div v-if="tokens" class="items-style">
                <div v-for="token in tokens" :key="token.id" class="w-100">
                    <router-link :to="'/startup/' + token.token.toLowerCase()">
                        <div class="items">
                            <div class="items-top">
                                <div v-if="token.svgIcon" class="pe-2">
                                    <startup-icon :token="token" />
                                </div>
                                <div>{{ token.token }}</div>
                                <svg viewBox="0 0 10 10" class="svg-token">
                                    <use xlink:href="/dist/svg/icons.svg#token" />
                                </svg>
                            </div>
                            <div class="items-top-condition">
                                <h3>{{ $t('intro.listing_desp') }}</h3>
                            </div>
                            <div class="items-middle">
                                <div class="middle-top">
                                    <div>
                                        {{ $t('startup.label_max_sub') + '/' + $t('startup.label_min_sub')
                                            + ':' + token.maxSubscriptionAmount + '/' + token.minSubscriptionAmount }}
                                    </div>
                                    <div>
                                        {{ $t('startup.label_offer_price') + ': ' + token.offerPrice }}
                                    </div>
                                </div>
                                <div class="middle-mid">
                                    <div>
                                        {{ $t('startup.label_progress') + ': ' + Math.floor((token.subscribedAmount /
                                            token.targetAmount) * 100) + '%' }}
                                    </div>
                                    <div>
                                        {{ token.targetAmount }}
                                    </div>
                                </div>
                                <div class="middle-desp">
                                    <token-progress :percentage="token.subscribedAmount / token.targetAmount" />
                                </div>
                            </div>
                            <div class="items-desp">
                                <div class="items-desp-font-1st">
                                    <h4>{{ $t('startup.orders_start_time') + ': ' }}</h4>
                                    <div>{{ new Date(token.startTime).formatDateTime() }}</div>
                                </div>
                                <div v-if="ongoing_tokens">
                                    <router-link :to="'/startup/' + token.token.toLowerCase()"
                                        class="btn btn-primary btn-pc">{{
                                            $t('startup.label_subscribe_sm') }}
                                    </router-link>
                                </div>
                                <div class="items-desp-font-2nd">
                                    <h4>{{ $t('startup.orders_end_time') + ': ' }}</h4>
                                    <div>{{ new Date(token.endTime).formatDateTime() }}</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="mobile-token">
                <div v-if="tokens" class="mobile-items-style">
                    <div v-for="token in tokens" :key="token.id">
                        <div class="mobile-items">
                            <div class="mobile-items-top">
                                <div class="mobile-items-topLeft">
                                    <div v-if="token.svgIcon" class="pe-2">
                                        <startup-icon :token="token" />
                                    </div>
                                </div>
                                <div class="mobile-items-topRight">
                                    <div>{{ token.token }}</div>
                                    <div>
                                        <h4>{{ $t('startup.orders_start_time') + ': ' }}</h4>{{ new
                                            Date(token.startTime).formatDateTime() }}
                                    </div>
                                </div>
                            </div>
                            <div class="mobile-items-middle">
                                <div class="m-middle-top">
                                    <div>
                                        {{ $t('startup.label_progress') + ': ' + Math.floor((token.subscribedAmount /
                                            token.targetAmount) * 100) + '%' }}
                                    </div>
                                    <div>
                                        {{ $t('startup.label_offer_price') + ': ' + token.offerPrice }}
                                    </div>
                                </div>
                                <div v-if="ongoing_tokens" class="m-midlle-desp">
                                    <token-progress-s-m :percentageSM="token.subscribedAmount / token.targetAmount" />
                                </div>
                            </div>
                            <div class="mobile-items-desp">
                                <div class="desp-column">
                                    <div>{{ $t('startup.label_max_sub') }}</div>
                                    <div>{{ token.maxSubscriptionAmount }}</div>
                                </div>
                                <div class="desp-column">
                                    <div>
                                        {{ $t('startup.label_min_sub') }}
                                    </div>
                                    <div>{{ token.minSubscriptionAmount }}
                                    </div>
                                </div>
                                <div v-if="ongoing_tokens">
                                    <router-link :to="'/startup/' + token.token.toLowerCase()"
                                        class="btn btn-primary">{{
                                            $t('startup.label_subscribe_sm') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </table>
        <loading-indicator v-if="!tokens" />
        <template v-else>
            <div v-if="tokens.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </template>
    </section>
</template>

<style scoped lang="less">
.table > :not(caption) > * > * {
    padding: 0;
}
/* tokenList table background-color->white--affected futures page's style */
.table>:not(caption)>*>* {
    color: #000922;
}

.startup-table td {
    vertical-align: middle;

}

.label-progress-thead {
    text-align: center;
}

.startup-table .display-none {
    display: none;
}

.startup-table .mobile-token {
    display: none;
}

.startup-table .items-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.startup-table .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-width: 500px;
    height: 300px;
    border: 1px solid #666;
    margin-top: 2rem;
    background: #fff;

    .items-top-condition {
        margin-bottom: 10px;

        h3 {
            font-size: 16px;
        }
    }
}

.startup-table .items-top {
    display: flex;
    padding-top: 10px;
    align-items: center;
    :nth-child(2) {
        font-size: 30px;
    }
}

.startup-table .items-middle {
    min-width: 500px;
    padding: 10px 8px;
    width: 100%;
    .middle-top {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .middle-mid {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
    }
}

.startup-table .items-desp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 8px;
    .items-desp-font-1st {
        display: flex;
        align-items: center;
        font-size: 11px;
        margin-bottom: 9px;

        h4 {
            font-size: 12px;
            margin: 0;
        }
    }

    .items-desp-font-2nd {
        display: flex;
        align-items: center;
        font-size: 11px;
        margin-bottom: 9px;

        h4 {
            font-size: 12px;
            margin: 0;
        }
    }
}

.svg-token {
    margin-top: 12px;
    margin-left: 6px;
    height: 20px;
}

.token-icon {
    height: 50px;
}
.btn-pc {
    height: auto;
    min-width: 100px;
}

@media(max-width: 1024px) {

    .startup-table .mobile-token {
        display: flex;
    }

    .startup-table .items-style {
        display: none;
    }

    .startup-table .mobile-items-style {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1rem;
    }

    .startup-table .mobile-items {
        display: flex;
        flex-direction: column;
        border: 1px solid #666;
        width: 100%;
        height: 200px;
        margin-top: 1rem;
        border-radius: 3px;
        background: #fff;
    }

    .startup-table .mobile-items-top {
        display: flex;
        flex-direction: row;
        margin-left: 1rem;
        margin-top: 1rem;

        .mobile-items-topRight {
            :nth-child(1) {
                font-size: 19px;
            }

            :nth-child(2) {
                color: #969699;
                font-size: 10px;

                :nth-child(1) {
                    font-size: 10px;
                }
            }
        }
    }

    .startup-table .mobile-items-middle {
        min-width: 330px;
        padding: 10px 14px;

        .m-middle-top {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-bottom: 10px;
            color: #969699;
        }

        .m-middle-mid {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
        }
    }

    .startup-table .mobile-items-desp {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        :nth-child(3) {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .desp-column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            :nth-child(1) {
                color: #969699;
                margin-left: 1rem;
            }

            :nth-child(2) {
                margin-top: 1rem;
                margin-left: 1rem;
                color: var(--primary-base);
            }
        }
    }
}
</style>

<script>
import StartupIcon from '../../Components/_StartupIcon.vue';
import TokenProgress from './_Progress.vue';
import TokenProgressSM from './_ProgressSM.vue';
import "@/assets/svg/ko-v4.2/token.svg"

export default {
    components: { StartupIcon, TokenProgress, TokenProgressSM },
    props: ['ongoing_tokens'],

    data() {
        return {
            tokens: null
        };
    },

    watch: {
        /**
         * Update token list when the ongoing_tokens property is updated.
         */
        ongoing_tokens: function () {
            this.tokens = null;
            this.getTokensAsync();
        }
    },

    mounted() {
        this.getTokensAsync();
    },

    methods: {
        getTokensAsync: async function () {
            const self = this;
            const json = await $.callGetApi(self, '/api/v1/startup/' + (this.ongoing_tokens ? 'ongoing' : 'forecast'));      
            if (json && json.errcode === 0) {
                self.tokens = json.data;

                Vue.nextTick(() => {
                    $.scanPopup();
                });
            }
        }
    }
};
</script>