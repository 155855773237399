<template>
    <section>
        <div class="filter-title">
            <div @click="hiddenModel">
                <img src="@/assets/images/ko-v4.2/go_back.png" alt="">
            </div>
            <div>
                {{ $t('balance.history_title') }}
            </div>
        </div>
        <table>
            <tbody>
                <tr v-for="rec in this.records" v-bind:key="rec.id">
                    <td>
                        <div v-if="rec.balanceChange != 0 && rec.balanceChange > 0 && isShowDepositOrders">
                            <div class="filter-order">
                                {{ $t("general.deposit") }}
                            </div>
                            <div class="filter-time">{{ new Date(rec.timeCreated).formatDateTime() }}</div>
                        </div>
                        <div v-else-if="rec.balanceChange != 0 && rec.balanceChange < 0 && isShowWithdrawOrders">
                            <div class="filter-order">
                                {{ $t("general.withdraw") }}
                            </div>
                            <div class="filter-time">{{ new Date(rec.timeCreated).formatDateTime() }}</div>
                        </div>
                        <div v-else>
                            <div class="filter-order">
                                {{ $t("home.header_orders") }}
                            </div>
                            <div class="filter-time">{{ new Date(rec.timeCreated).formatDateTime() }}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <global-mask v-if="isShowGlobalMask"/>
        <filter-model-actions v-if="isShowFilterModelActions" class="action-float" @showDeposit="showDepositOrders"
            @showWithdraw="showWithdrawOrders" @confirmMsg="confirmMsg" @cancelCls="cancelCls" :records="records"></filter-model-actions>
    </section>
</template>
<style scoped lang="less">
.show-none {
    width: 0;
    height: 0;
}

.action-float {
    position: fixed;
    height: 100%;
    width: 70%;
    right: 0rem;
    background: #fff;
    bottom: 0rem;
    z-index: 4;
}

.filter-title {
    display: flex;
    justify-content: flex-start;
    margin-top: 20%;

    :last-child {
        margin-left: 1rem;
    }

    :first-child {
        margin-left: 0;

        img {
            height: 1rem;
        }
    }
}

.filter-order {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #111111;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.filter-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 18px;
    text-align: left;
    font-style: normal;
}
</style>
<script>
import { watch } from "less";
import GlobalMask from "../../User/Components/GlobalMask.vue";
import FilterModelActions from "./FilterModelActions.vue";

export default {
    data() {
        return {
            isShowDepositOrders: true,
            isShowWithdrawOrders: true,
            isShowFilterModelActions:true,
            isShowGlobalMask:true,
            selectCurrency:"",
        }
    },
    props: {
        records: null,
        showGlobalMask: false,
        // child->father
        //isShowFilterModel:false,
        //hiddenFilter: function () {}
    },
    components: { GlobalMask, FilterModelActions },
    //The method called by the grandchild component affects the data in the master component
    inject: ['hideFilterModel'],
    methods: {
        hiddenModel: function () {
            //grandson->grandpa
            this.hideFilterModel();
            // child->falther
            //return this.$emit("hiddenFilter");
        },
        showDepositOrders: function () {
            this.isShowDepositOrders = true;
            this.isShowWithdrawOrders = false;
        },
        showWithdrawOrders: function () {
            this.isShowDepositOrders = false;
            this.isShowWithdrawOrders = true;
        },
        confirmMsg:function(){
            this.isShowFilterModelActions=false;
            this.isShowGlobalMask=false;
        },
        cancelCls:function(){
            this.isShowFilterModelActions=false;
            this.isShowGlobalMask=false;
            this.isShowDepositOrders = true;
            this.isShowWithdrawOrders = true;
        }
    }
}
</script>
