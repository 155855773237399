<template>
    <div>
        <div id="outside-sm">
            <div id="inside-sm" :style="`width: ${this.setWidth}${'px'};`" >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            outsideWidth:0,
            setWidth:0,
            newWidth:0
        }
    },
    props: ['percentageSM'],
    watch:{
        newWidth:function(newVal,val){
            this.calOutsideWidth();
        },
        deep:true
    },
    mounted() {
        const el = document.getElementById("outside-sm");
        this.newWidth=this.$el.getBoundingClientRect().width;
        this.outsideWidth=this.newWidth===null||this.newWidth===0 ?300:this.newWidth;
    },
    methods:{
        calOutsideWidth:function(){
        this.setWidth=this.percentageSM * this.outsideWidth>this.outsideWidth?this.outsideWidth:this.percentageSM * this.outsideWidth;
        }
    }
};
</script>

<style>
#outside-sm {
    width: '${this.outsideWidth}${'px'}';
    background: #EDEEEF;
    height: 5px;
    border-radius: 10px;
    max-width: 100%;
}

#inside-sm {
    height: 5px;
    background: #4d9eaf;
    border-radius: 10px;
}
</style>