<template>
    <section class="page settings-page">
        <div class="w-100">
            <div class="container">
                <div class="withdraw-title">
                    <div>
                        <router-link to="/user/balance">
                            <img src="@/assets/images/ko-v4.2/go_back.png" alt="">
                        </router-link>
                    </div>
                    <div>
                        <h1>{{ $t('general.withdraw') }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <loading-indicator v-if="!withdrawConfig" />
                    <template v-else>
                        <div v-if="withdrawConfig.errcode !== 0" class="alert alert-info">
                            {{ withdrawConfig.errmsg }}
                        </div>
                        <template v-else>
                            <!--
                                Identity verification
                            -->
                            <div v-if="withdrawConfig.data.identityVerified !== true">
                                <div class="alert alert-info">
                                    {{ $t('user.label_id_verification_desp') }}
                                    <div class="mt-3">
                                        <router-link to="/user/verifyid" class="btn btn-primary">{{
                                            $t('user.label_id_verification') }}</router-link>
                                    </div>
                                </div>
                            </div>

                            <!--
                                Indicates a withdraw password must be set before a withdraw operation if asked by system settings.
                            -->
                            <div
                                v-else-if="withdrawConfig.data.requiresWithdrawPassword === true && withdrawConfig.data.isWithdrawPasswordSet !== true">
                                <div class="alert alert-info">
                                    {{ $t('withdraw.missing_withdraw_pwd') }}
                                    <div class="mt-3">
                                        <router-link to="/user/changewithdrawpwd" class="btn btn-primary">{{
                                            $t('withdraw_password.title') }}</router-link>
                                    </div>
                                </div>
                            </div>

                            <!--
                                Actual withdraw forms
                            -->
                            <template v-else>
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;"
                                            v-bind:class="{ active: blockChainWithdraw }"
                                            @click="blockChainWithdraw = true">
                                            {{ $t('withdraw.label_coin_withdraw') }}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="javascript:;"
                                            v-bind:class="{ active: !blockChainWithdraw }"
                                            @click="blockChainWithdraw = false">
                                            {{ $t('withdraw.label_fiat_withdraw') }}
                                        </a>
                                    </li>
                                </ul>
                                <div class="card mt-0">
                                    <div class="card-body">
                                        <!-- Blockchain withdraw -->
                                        <block-chain-withdraw-form v-if="blockChainWithdraw"
                                            :currency="withdrawToken" />
                                        <template v-else>
                                            <withdraw-form-component />
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped lang="less">
.w-100 {
    background-color: #F5F5F7;
}

.withdraw-title {
    /* background: #F5F5F7; */
    display: flex;
    align-items: center;
    h1 {
        font-family: PingFangSC, PingFang SC;
        margin-left: 10px;
    }
}
</style>
<script>
import WithdrawFormComponent from './Components/WithdrawForm.vue';
import BlockChainWithdrawForm from './Components/BlockChainWithdrawForm.vue';

export default {
    components: { WithdrawFormComponent, BlockChainWithdrawForm },

    /**
     * id: specify the name of the currenty for withdraw.
     */
    props: ['id'],

    data() {
        return {
            withdrawConfig: null,
            // Indicates whether to display blockchain withdarw or not.
            blockChainWithdraw: false,

            // The token for withdraw.
            withdrawToken: null
        };
    },

    watch: {
        '$i18n.locale': function () {
            this.getWithdrawConfigAsync().catch((err) => {
                this.withdrawConfig = {
                    // Unexpected error
                    errcode: -1,
                    errmsg: self.$t('general.http_error')
                };
            });
        }
    },

    created() {
        this.withdrawToken = (this.id || 'USDT').toUpperCase();

        const self = this;
        self.getWithdrawConfigAsync().catch((err) => {
            self.withdrawConfig = {
                // Unexpected error
                errcode: -1,
                errmsg: self.$t('general.http_error')
            };
        });
    },

    methods: {
        getWithdrawConfigAsync: async function () {
            const self = this;
            const config = await $.callGetApi(self, '/api/v1/finance/withdrawconfig');
            if(config && config.errcode === 100 ){
                self.withdrawConfig = {
                    errcode: 100,
                    errmsg: self.$t('withdraw.label_no_date')
                };
            } else {
            self.withdrawConfig = Object.freeze(config);
            }
        }
    }
};
</script>