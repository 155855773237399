<template>
    <div v-if="isShow" class="row">
        <div class="col balance-list">
            <loading-indicator v-if="!balance_list" />

            <dl v-else v-for="balance in balance_list" class="currency-row" :key="balance.currency"
                v-show="balance.currency == Bname">
                <dd>
                    <!-- <div class="pe-2"><token-icon :symbol="symbol" /></div> -->
                    <div>{{ getTokenName(balance) }}</div>
                    <div class="mobile-balance1">{{ $t('assets.balance') }}</div>
                    <div class="mobile-balance2">{{ $t('assets.available_balance') }}{{ ':' }}</div>
                    <div class="mobile-balance3">{{ $t('assets.frozen') }}{{ ':' }}</div>
                    <div>{{ balance.balance }}</div>
                    <div class="num-style" v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div class="num-style" v-else>{{ balance.available }}</div>
                    <div class="num-style">{{ balance.frozen }}</div>
                    <div class="operation-img" @click="hiddenModel">
                        <svg viewBox="0 0 256 256" class="svg-closeButton">
                            <use xlink:href="/dist/svg/icons.svg#closeButton" />
                        </svg>
                    </div>
                    <div class="actions">
                        <template v-if="isDepositSupported(balance.currency)">
                            <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                {{ $t('general.deposit') }}
                            </a>
                            <router-link class="deposite"
                                :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{
                                    $t('general.withdraw') }}</router-link>
                        </template>
                        <template v-else-if="balance.currency !== 'FTUSDT'">
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                        </template>
                    </div>
                </dd>
                <!-- display deposit address -->
                <div v-if="isDepositSupported(balance.currency)&& showDeposit" class="deposit-address-block deposit-mobile" @touchmove.prevent>
                    <deposit-form :bcConfig="bcConfig" :coinConfig="getCoinConfig(balance.currency)" @closeModel="closeModel" />
                </div>
                <global-mask-trade :isMaskShow="isMaskShow" @closeMaskC="closeMaskF"></global-mask-trade>
            </dl>
        </div>
    </div>
</template>
<style scoped lang="less">
.svg-closeButton {
    height: 1rem;
}

.balance-list {
    border-radius: 20px 20px 0px 0px;
}

.balance-page .balance-page-mobile .balance-list dl dd {
    background: #FAFAFA;
    height: 40%;
    margin-top: 3%;
    border-top: none;
    border-radius: 4px;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row {
    background: #fff;
    height: 16rem;
    border: none;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div.actions {
    display: flex;
    position: absolute;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    width: 100%;
    top: 12rem;
    right: 0rem;
    min-width: 100%;
    justify-content: space-between;

    :first-child {
        flex-grow: inherit;
    }

    :last-child {
        flex-grow: inherit;
    }
}

.balance-list dl.currency-row .actions a {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 4px;
    border: 1px solid #4D9EAF;
    min-width: 12rem;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div.mobile-balance1 {
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
    top: 4rem;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div.mobile-balance2,
.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div.mobile-balance3 {
    right: 13rem;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div:nth-child(5) {
    margin-top: 0rem;
    top: 6.5rem;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div:nth-child(6),
.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div:nth-child(7) {
    right: 3rem;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div:first-child {
    padding-left: 24px;
    padding-top: 0;
}

.top-float .row>* {
    padding-left: 0;
    padding-right: 0;
}

.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div.operation-img {
    padding-right: 0.9rem;
    padding-top: 0;
}
@media (max-width: 1024px) {
    .row>* {
        padding: 0px;
    }
}
   
</style>
<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';
import GlobalMaskTrade from '../../Components/GlobalMask_Trade.vue';
import '@/assets/svg/ko-v4.2/closeButton.svg';
export default {
    components: { DepositForm,GlobalMaskTrade },
    props: {
        balance_list: null, futures_assets: null, bcConfig: null, Bname: null, isShow: false
        , hiddenMask: function () { }
    },
    data() {
        return {
            coinMap: {},
            // The active currency for deposits
            deposit_currency: '',
            isMaskShow: false,
            showDeposit:false
        }
    },
    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);
    },

    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];           
        },

        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
            this.isMaskShow = true;
            this.showDeposit=true;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        },
        hiddenModel: function () {
            //The child component controls the closing of the mask layer of the parent component
            return this.$emit("hiddenMask");
        },
        closeMaskF:function(){
            this.isMaskShow=false;
            this.showDeposit=false;
        },
        closeModel:function(){
            this.$emit("hiddenMask");
        }
    }
}
</script>