<template>
    <section>
        <!-- Reading token config first -->
        <loading-indicator v-if="!withdrawConfig" />
        <form v-else class="form-horizontal" method="post" action="javascript:" @submit="do_confirm">
            <div class="form-group">
                <label class="form-label">
                    {{ $t('withdraw.label_target_address') }}
                    <span v-if="currency !== 'USDT'">({{ currency }})</span>
                </label>
                <div class="row">
                    <div class="col-md-4">
                        <!-- Specical case for USDT which supports 2 tokens -->
                        <select  v-if="currency === 'USDT'" v-model="token" class="form-control form-select" data-val="*"
                            data-val-required="*" name="Coin">
                            <option value="USDT">USDT-ERC20</option>
                            <option value="USDT_TRC" selected>USDT-TRC20</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <input v-model="address" type="text" name="Address" class="form-control" data-val="true"
                            data-val-required="*" :placeholder="$t('withdraw.label_target_address')" />
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col">
                    <label class="form-label"> {{ $t('withdraw.label_amount') }} </label>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" name="Amount" v-model="amount" class="form-control"
                                    autocomplete="off" data-val="true" data-val-required="*" data-val-number="*"
                                    data-val-range="*" v-bind:data-val-range-min="withdrawConfig.minWithdrawAmount"
                                    v-bind:data-val-range-max="maxAmountPerOrder" />

                                <div class="input-group-text">{{ currency }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="help-block">
                        <div>
                            {{ $t('withdraw.label_available_amount') }} <span>{{
                                withdrawConfig.maxAvailableAmount.toBalanceString() }} {{ currency }}</span>
                        </div>
                        <div>
                            <span v-if="withdrawConfig.maxWithdrawAmount < 0x7fffffff">
                                {{
                                    $t('withdraw.label_amount_range', {
                                        min: withdrawConfig.minWithdrawAmount.toBalanceString(),
                                        max: withdrawConfig.maxWithdrawAmount,
                                        currency_symbol: currency
                                    })
                                }}
                            </span>
                            <span v-else>{{ $t('withdraw.label_min_amount', {
                                min: withdrawConfig.minWithdrawAmount,
                                currency_symbol: currency
                            }) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row" v-if="withdrawConfig.requiresWithdrawPassword === true">
                <div class="col">
                    <label class="form-label"> {{ $t('withdraw.label_withdraw_pwd') }} </label>
                    <div class="row">
                        <div class="col-md-4">
                            <input type="password" name="WithdrawPassword" v-model="password" class="form-control"
                                autocomplete="off" data-val="true" data-val-required="*"
                                data-val-regex-pattern="^\d{6}$" data-val-regex="*" data-val-maxlength="*"
                                data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="6" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col col-md-4">
                    <button type="submit" class="btn btn-submit">{{ $t('general.submit') }}</button>
                </div>
            </div>
        </form>

        <!-- A confirmation modal -->
        <div ref="confirmModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form action="javascript:void(0);" method="post" @submit="do_submit">
                        <!-- modal header -->
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('general.confirm') }}</h4>
                            <button type="button" class="close" data-bs-dismiss="modal"
                                aria-hidden="true">&times;</button>
                        </div>

                        <!-- modal body -->
                        <div class="modal-body">
                            <input type="hidden" name="Coin" v-model="token" />
                            <input type="hidden" name="Address" v-model="address" />
                            <input type="hidden" name="Amount" v-model="amount" />
                            <input type="hidden" name="WithdrawPassword" v-model="password" />
                            <div class="form-group row">
                                <div class="col">
                                    <label class="form-label"> {{ $t('withdraw.label_target_address') }} </label>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b v-if="token === 'USDT_TRC'">USDT-TRC20</b>
                                            <b v-else-if="token === 'USDT'">USDT-ERC20</b>
                                            <b v-else>{{ token }}</b>
                                            <div>{{ address }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col">
                                    <label class="form-label"> {{ $t('withdraw.label_amount') }} </label>
                                    <div class="row">
                                        <div class="col-md-4">{{ amount }} {{ currency }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- modal footer -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{
                                $t('general.cancel') }}</button>
                            <button type="submit" class="btn btn-primary" :class="{ 'btn-loading': submitting }"
                                :disabled="submitting">{{ $t('general.confirm') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    props: ['currency'],

    data() {
        return {
            // Config for block-chain withdraws.
            withdrawConfig: null,

            token: this.currency === 'USDT' ? 'USDT_TRC' : this.currency,
            address: '',
            amount: '',
            password: '',

            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    computed: {
        maxAmountPerOrder: function () {
            const config = this.withdrawConfig;
            return Math.min(config.maxWithdrawAmount, config.maxAvailableAmount);
        }
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.confirmModal);
        this.getBCConfigAsync();
    },

    methods: {
        do_confirm: function (e) {
            const paymentSource = this.withdrawConfig.paymentSource;
            if (!paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(paymentSource)) throw new Error('Requires a valid payment source.');

            const frm = $(e.target);
            if (frm.valid()) {
                this.submitting = false;
                // $(this.$refs.confirmModal).modal('show');
                this.popupModal.show();
            } else {
                // Is it because of invalid withdraw amount
                const str = $.trim('  ' + this.amount);
                if (str.length > 0) {
                    const amt = this.amount * 1;
                    if (!isNaN(amt)) {
                        if (amt > this.maxAmountPerOrder) {
                            $.top_error(this.$t('withdraw.err_amount_exceeded'));
                        } else if (amt < this.withdrawConfig.minWithdrawAmount) {
                            $.top_error(this.$t('withdraw.err_too_small_amount'));
                        }
                    }
                }
            }
        },

        do_submit: function (e) {
            const paymentSource = this.withdrawConfig.paymentSource;
            if (!paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(paymentSource)) throw new Error('Requires a valid payment source.');

            const self = this;
            const frm = $(e.target);
            if (frm.valid()) {
                const data = frm.serializeAsJson();

                // Submit to server now.
                self.submitting = true;
                $.callPostApi(self, '/api/v1/udun/withdraw', data)
                    .then(function (json) {
                        if (json && json.errcode === 0) {
                            self.submitting = false;
                            $.top_alert(json.errmsg || self.$t('general.operation_succeeded'));
                        } else {
                            $.top_error(json.errmsg || self.$t('general.http_error'));
                        }

                        // close the modal
                        // $(self.$refs.confirmModal).modal('hide');
                        self.popupModal.hide();

                        // Fire an event to notify the completion of a withdraw order.
                        // self.$emit('submitted');
                        self.getBCConfigAsync();
                    })
                    .catch(function () {
                        $.top_error(self.$t('general.http_error'));
                    })
                    .then(() => {
                        self.submitting = false;
                    });

                return false;
            }
        },

        getBCConfigAsync: async function () {
            const resp = await $.callPostApi(this, '/api/v1/finance/blockChainWithdrawQuota?currency=' + encodeURIComponent(this.currency));
            if (resp) {
                if (resp.errcode !== 0) {
                    $.top_error(resp.errmsg || this.$t('general.operation_error'));
                } else {
                    this.withdrawConfig = Object.freeze(resp.data);

                    Vue.nextTick(() => {
                        $.resetValidators();
                    });
                }
            }
        }
    }
};
</script>