<template>
    <div class="intro">
        <div class="pc-container">
            <div class="container">
                <div class="otc-title">
                    <h1>{{ $t('v42.security_title', { platform: sysconfig.name }) }}</h1>
                    <p>{{ $t('v42.security_title') }}</p>
                </div>
                <div class="otc-intro">
                    <div class="list-box">
                        <div class="list">
                            <div class="img"><img src="@/assets/images/ko-v4.2/pic-help1.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.security_t1') }}</p>
                                <p class="p">{{ $t('v42.security_desp1') }}</p>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img"><img src="@/assets/images/ko-v4.2/pic-help2.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.security_t2') }}</p>
                                <p class="p">{{ $t('v42.security_desp2') }}</p>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img3"><img src="@/assets/images/ko-v4.2/pic-help3.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.security_t3') }}</p>
                                <p class="p">{{ $t('v42.security_desp3') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exchange-title">
                    <h3>{{ $t("intro.any_platform") }}</h3>
                    <h3>{{ $t("intro.any_platform_desp") }}</h3>
                </div>
                <div class="exchange-intro">
                    <div class="exchange-intro-left">
                        <div class="exchange-intro-left-top">
                            <div class="left-top-img1">
                                <img src="@/assets/images/ko-v4.2/AppStore.png" alt="">
                            </div>
                            <!-- <div class="left-top-img2">
                                <img src="@/assets/images/ko-v4.2/GooglePlay.png" alt="">
                            </div> -->
                            <div class="left-top-img3">
                                <img src="@/assets/images/ko-v4.2/AndroidAPK.png" alt="">
                            </div>
                            <div class="left-top-img4">
                                <img src="@/assets/images/ko-v4.2/Windows.png" alt="">
                            </div>
                        </div>
                        <div class="exchange-intro-left-topMobile">
                            <div class="exchange-intro-left-topMobile-top">
                                <div class="left-top-img1">
                                    <img src="@/assets/images/ko-v4.2/AppStore.png" alt="">
                                </div>
                                <!-- <div class="left-top-img2">
                                    <img src="@/assets/images/ko-v4.2/GooglePlay.png" alt="">
                                </div> -->
                            </div>
                            <div class="exchange-intro-left-topMobile-bottom">
                                <div class="left-top-img3">
                                    <img src="@/assets/images/ko-v4.2/AndroidAPK.png" alt="">
                                </div>
                                <div class="left-top-img4">
                                    <img src="@/assets/images/ko-v4.2/Windows.png" alt="">
                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="exchange-intro-right">
                        <img src="@/assets/images/ko-v4.2/computer.png" alt="" class="computer-img">
                    </div>
                </div>
            </div>




        </div>
    </div>
</template>
<script>
export default {
    props: {
        securityList: {
            type: Array,
            default: () => {
                return []
            }
        },
        otcList: {
            type: Array,
            default: () => {
                return []
            }
        },
        introList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            fileName: 'dist2',
            carouselList: [],
            activeName: 'first',
            introSwitch: 1
        }
    },
    created() { },
    methods: {
        handleClick(tab, event) {
            // console.log(tab)
            // let isActive = (event.srcElement.style.color = '#000')
            // setTimeout(() => {
            //     if (isActive) {
            //         event.srcElement.style.color = '#000'
            //     } else {
            //         event.srcElement.style.color = '#fff'
            //     }
            // }, 0.3)
            // console.log()
        }
    }
}
</script>
<style lang="less" scoped>
.tab-intro {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    margin-top: 300px;

    .left {
        margin-left: 105px;
        margin-right: 155px;
    }

    .right {
        padding-top: 183px;

        .title {
            font-size: 46px;
            line-height: 56px;
            font-weight: 600;
            color: var(--text-title);
            margin-bottom: 100px;
        }
    }
}

.otc-title {
    text-align: center;
    margin-bottom: 120px;
    margin-top: 100px;

    h1 {
        display: none;
    }

    h3 {
        font-size: 46px;
        font-weight: 600;
        line-height: 52px;
        margin-bottom: 15px;
        color: #000922;
    }

    p {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #40475A;
    }
}

.otc-intro {
    margin-bottom: 100px;

    .list-box {
        display: flex;
        justify-content: space-around;
    }

    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        .img {
            width: 58px;
            margin-bottom: 20px;
        }

        .img3 {
            width: 43px;
            margin-bottom: 20px;
        }

        .desp {
            flex: 1;
            max-width: 100%;

            .t {
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 20px;
                text-align: center;
                color: #000922;
            }

            .p {
                text-align: center;
                // width: 240px;//在外层设置max-width随页面的变动自动撑满盒子
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #7F8490;
            }
        }
    }

    .right {
        width: 386px;
        margin-top: 30px;
    }
}

.news-title {
    margin-top: 300px;
    margin-bottom: 64px;

    h3 {
        margin-bottom: 30px;
        font-size: 46px;
        font-weight: 600;
    }

    p {
        max-width: 581px;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: var(--text-desp);
    }
}

.news-intro {
    margin-bottom: 300px;

    .list-box {
        justify-content: space-between;
        display: flex;

        .list {
            margin-right: 19px;

            &:last-child {
                margin-right: 0;
            }
        }

        .img {
            margin-bottom: 24px;

            img {
                max-width: 100%;
                width: 388px;
                height: 242px;
                display: block;
                margin: 0 auto;
            }
        }

        .desp {
            .t {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                margin-bottom: 12px;
            }

            .p {
                line-height: 18px;
                color: var(--text-desp);
            }
        }
    }
}


.exchange-title {
    text-align: left;
    margin-bottom: 70px;
    margin-top: 0px;

    h3 {
        font-size: 46px;
        font-weight: 600;
        line-height: 52px;
        margin-bottom: 15px;
        color: #000922;
    }

}

.exchange-intro {
    display: flex;
    justify-content: space-between;

    .exchange-intro-left {
        display: flex;
        flex-direction: column;

        .exchange-intro-left-topMobile {
            display: none;
        }

        .exchange-intro-left-top {
            display: flex;
            //每一个占二分之一，允许换行
            flex-direction: calc(1/2);
            flex-wrap: wrap;
            width: 500px;
            margin-bottom: 20px;

            .left-top-img1 {
                margin-right: 5px;
                margin-bottom: 5px;
            }

            .left-top-img2 {
                margin-left: 5px;
                margin-bottom: 5px;
            }
        }

        .exchange-intro-left-bottom {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .computer-in-Mobile {
                display: none;
            }
            p {
                margin-left: 20px;
                margin-top: 30px;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: #40475A;
            }

        }
    }

    .exchange-intro-right {
       width: 100%;
       .computer-img{
        width: 100%;
       }
    }
}

@media (max-width: 1024px) {
    .pc-container {
        .otc-title {
            padding: 0 28px;
            box-sizing: border-box;
            margin: 20px 0 51px 0;

            h1 {
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                display: block;
                font-size: 14px;
                font-weight: bold;
                color: #000922;
            }

            h3 {
                display: none;
                margin: 100px 0 12px 0;
                font-size: 23px;
                font-weight: 600;
                line-height: 28px;
            }

            p {
                display: none;
                font-size: 11px;
                line-height: 17px;
                text-align: center;
                color: var(--text-desp);
            }
        }

        .otc-intro {
            margin-bottom: 0;



            .list-box {
                display: flex;
                flex-direction: column;
                align-items: center;

                .list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    margin-top: 18px;

                    .img {


                        height: auto;
                    }

                    .desp {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        max-width: 100%;
                        padding: 0 16px;

                        font-size: 14px;
                        font-weight: 600;
                        color: var(--text-title);
                        line-height: 20px;

                        .t {
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 18px;
                            margin-bottom: 4px;
                        }

                        .p {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            margin-top: 10px;
                            color: #7F8490;

                        }
                    }
                }
            }
        }

        .platform,
        .platform-title {
            display: none;
            // .right {
            //     display: none;
            // }
        }
    }

    .tab-intro {
        flex-direction: column-reverse;
        margin: 100px 0;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        .left {
            flex: 1;
            margin: 0;

            img {
                display: block;
                width: 210px;
                margin: 0 auto;
            }
        }

        .right {
            margin-bottom: 50px;
            border-radius: 16px;
            padding-top: 0;
            width: 100%;

            .title {
                text-align: center;
                font-size: 23px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 0;
                margin-bottom: 32px;
            }

            .tabs-box {
                width: 274px;
                margin: 0 auto;

                /deep/.el-tabs__header {
                    margin-bottom: 28px;

                    .el-tabs__nav-wrap {
                        padding: 0;
                        border-radius: 16px;
                    }

                    .el-tabs__nav {
                        height: 32px;
                        width: 274px;

                        .el-tabs__item {
                            font-size: 12px;
                        }
                    }

                    .el-tabs__active-bar {
                        height: 28px;
                        top: 2px;
                        left: 2px;
                        border-radius: 14px;
                    }
                }

                /deep/.el-tabs__content {
                    .el-tab-pane {
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .news-title {
        margin: 100px 0 50px 0;

        h3 {
            font-size: 23px;
            margin-bottom: 16px;
            text-align: center;
        }

        p {
            text-align: center;
            font-size: 12px;
        }
    }

    .news-intro {
        margin-bottom: 100px;

        .list-box {
            display: block;

            .list {
                margin-bottom: 50px;
                margin-right: 0;

                .img {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 343px;
                }
            }

            .desp {
                text-align: center;

                .t {
                    font-size: 14px;
                    margin-bottom: 16px;
                }

                .p {
                    margin-bottom: 50px;
                    font-size: 12px;
                }
            }
        }
    }

    .exchange-title {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 2rem;

        h3 {
            font-size: 14px;
        }

    }

    .exchange-intro {
        height: 16rem;
        width: 100%;
        background-size: cover;
        padding-bottom: 0;

        .exchange-intro-right {
            display: none;
        }

        .exchange-intro-left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .exchange-intro-left-topMobile {
                display: flex;
                flex-direction: column-reverse;

                .exchange-intro-left-topMobile-top {
                    display: flex;
                    justify-content: center;
                    margin-top: 1rem;
                    .left-top-img1 {
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }

                    .left-top-img2 {
                        margin-left: 5px;
                        margin-bottom: 5px;
                    }
                }

                .exchange-intro-left-topMobile-bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .left-top-img4 {
                       margin-top: 1rem;
                    }
                }
            }

            .exchange-intro-left-top {
                display: none;
            }

            .exchange-intro-left-bottom {
                display: flex;
                flex-direction: column;

                img {
                    border: none;
                }

                p {
                    margin-left: 0;
                }

                .computer-in-Mobile {
                    margin-top: 5rem;
                    display: block;

                    img {
                        border: none;
                        
                    }
                }
            }
        }

        .title {
            font-size: 23px;
            margin-bottom: 45px;
            font-weight: 600;
            width: auto;
        }

        .link {
            font-size: 14px;
            height: 46px;
            line-height: 46px;
            border-radius: 23px;
            padding: 0 32px;
        }
    }
}

@media (max-width: 1200px) {
    .tab-intro {
        flex-direction: column-reverse;
        align-items: center;
        margin: 100px auto;
        width: 100%;
        max-width: 1024px;
        padding: 0 30px;
        box-sizing: border-box;

        .left {
            flex: 1;
            margin: 0;
        }

        .right {
            margin-bottom: 50px;
            border-radius: 16px;
            padding-top: 0;
            width: 100%;

            .title {
                text-align: center;
                font-weight: 600;
                margin-bottom: 0;
                margin-bottom: 32px;
            }

            .tabs-box {
                margin: 0 auto;
            }
        }
    }
}

.switch-top {
    display: flex;
    align-items: center;
    height: 64px;
    width: 548px;
    border: 1px solid var(--text-desp);
    border-radius: 64px;
    overflow: hidden;
    margin-bottom: 28px;

    .link {
        color: var(--text-desp);
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        padding: 0;
        position: relative;
        text-align: center;
        z-index: 1;
        border-radius: 28px;
        height: 56px;
        line-height: 56px;
        left: 4px;
        top: 0px;

        &:last-child {
            right: 4px;
            left: auto;
        }

        &.active {
            color: var(--text-title);
        }
    }
}

.switch-content {
    color: var(--text-desp);
    font-size: 20px;
    line-height: 26px;
    text-align: center;

}

@media (max-width: 1024px) {
    .switch-top {
        width: 274px;
        height: 32px;
        margin: 0 auto;
        margin-bottom: 28px;

        .link {
            height: 26px;
            line-height: 26px;
            border-radius: 14px;
            font-size: 12px;
            left: 2px;

            &:last-child {
                right: 2px;
                left: auto;
            }
        }

    }

    .switch-content {
        width: 274px;
        color: var(--text-desp);
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin: 0 auto;
    }


}
</style>
