<template>
    <div v-if="isShowList" class="row">
        <div class="curency-list-title">
            <div class="title-items">
                {{ $t('balance.title_currency') }}
            </div>
            <div class="operation-img">
                <svg viewBox="0 0 256 256" class="svg-closeButton" @click="hiddenModel">
                    <use xlink:href="/dist/svg/icons.svg#closeButton" />
                </svg>
            </div>
        </div>
        <!-- search -->
        <div class="input-group input-group-lg-mobile">
            <div class="input-group-text">
                <svg viewBox="0 0 1024 1024" width="20" height="20">
                    <path
                        d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z"
                        fill="#666666" p-id="2868"></path>
                </svg>
            </div>
            <input type="text" class="form-control" v-model="search_term" placeholder="搜索" />
        </div>
        <div class="col balance-list">
            <loading-indicator v-if="!balance_list" />
            <dl v-else-if="!search_term" v-for="balance in balance_list" :key="balance.currency">
                <dd>
                    <div>{{ getTokenName(balance) }}</div>
                </dd>
            </dl>
            <dl v-else-if="this.search_term && filterSearchResult">
                <dd v-for="(balance, index) in filterSearchResult" :key="index"
                    @click="showActionsModelInfluencedCurency(balance)">
                    <template v-if="balance.currency !== 'FTUSDT'">
                        <div>{{ balance.display_name }}</div>
                    </template>
                </dd>
            </dl>
        </div>
    </div>
    <div v-else-if="isShow" class="currency-list-model">
        <currency-actions-model class="top-float" ref="actionsModel2" :balance_list="balance_list" :Bname="Bname"
            :isShow="isShow" :bcConfig="bcConfig" :futures_assets="futures_assets"
            @hiddenMask="hideMask"></currency-actions-model>
    </div>
</template>
<style scoped lang="less">
.balance-page .balance-page-mobile .balance-list dl.currency-row dd>div.operation-img {
    padding-right: 0.5rem;
    padding-top: 0;
}

.curency-list-title {
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 100%;
    padding: 2rem 1rem 1rem 1rem !important;
    border-radius: 15px 15px 0px 0px;

    .title-items {
        font-size: larger;
        color: #111111;
        font-weight: bold;
        padding-left: 0rem;
    }
}

.svg-closeButton {
    height: 1rem;
}

.input-group-text {
    padding: 0 0.5rem;
}

.balance-list {
    margin: 0;
}

.balance-page .balance-page-mobile .balance-list dl dd {
    padding: 15px 0;
    border: none;
    color: #111111;
    font-weight: 500;
}
.row{
    --bs-gutter-x:0;
}
.row>* {
    padding: 0 1rem;
    margin-left: 0;
}

/*
****************************************
currencyActionsModel style in currencyListModel
*/
.currency-list-model {
    .row {
        --bs-gutter-x: 0;
    }
}
</style>
<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';
import CurrencyActionsModel from '../Components/CurrencyActionsModel.vue';
import '@/assets/svg/ko-v4.2/closeButton.svg';

export default {
    components: { DepositForm, CurrencyActionsModel },
    props: {
        balance_list: null, futures_assets: null, bcConfig: null, isShowList: false, isShow: false, hiddenMask: function () { }, showActionsModelInfluencedCurencyList: function () { }
    },
    data() {
        return {
            coinMap: {},
            // The active currency for deposits
            deposit_currency: '',
            //search
            search_term: null,
            Bname: '',
            isShowList: false,
        }
    },
    computed: {
        filterSearchResult: function () {
            const term =
                this.search_term && typeof this.search_term === 'string'
                    ? this.search_term.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                return this.balance_list.filter((s) => {
                    return s.currency.indexOf(term) >= 0;
                });
            } else {
                // Return symbols for the specified type.
                return this.balance_list.filter((s) => {
                    return false;
                });
            }
        }
    },
    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);
    },
    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];
        },

        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        },
        hiddenModel: function () {
            //The child component controls the closing of the mask layer of the parent component
            return this.$emit("hiddenMask");
        },
        showActionsModelInfluencedCurency: function (name) {
            //Click on the search page to pop up ActionsModel
            this.getBname(name);
            //return this.$emit("showActionsModelInfluencedCurency");
        },
        getBname: function (name) {
            this.Bname = this.getTokenName(name);
            this.isShow = true;
            this.isShowList = false
            this.$refs.actionsModel.showModal()
        },
        hideMask() {
            this.hiddenModel();
        }
    }
}
</script>